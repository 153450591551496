export const getJukuById = /* GraphQL */ `
  query getJukuById($jukuId: Int!) {
    getJukuById(jukuId: $jukuId) {
      id
      corporateName
      jukuName
      jukuCode
      domain
      memo
      classrooms {
        id
      }
    }
  }
`;

export const getJukuConfigByJukuId = /* GraphQL */ `
  query getJukuConfigByJukuId($jukuId: Int!) {
    getJukuConfigByJukuId(jukuId: $jukuId) {
      id
      jukuId
      # promotionDate
      currentFiscalYear
    }
  }
`;

export const getGuidanceJukuTestHistory = /* GraphQL */ `
  query getGuidanceJukuTestHistory($guidanceJukuTestTemplateId: Int!, $studentId: Int!) {
    getGuidanceJukuTestHistory(
      guidanceJukuTestTemplateId: $guidanceJukuTestTemplateId
      studentId: $studentId
    ) {
      createdAt
      id
      testResult
      score
      perfectScore
      guidanceReportId
      retestFlag
      komaName
    }
  }
`;

export const getGuidanceJukuTestWithSummaries = /* GraphQL */ `
  query getGuidanceJukuTestWithSummaries(
    $parentFolderId: Int!
    $studentId: Int!
    $startDate: String!
    $endDate: String!
  ) {
    getGuidanceJukuTestWithSummaries(
      parentFolderId: $parentFolderId
      studentId: $studentId
      startDate: $startDate
      endDate: $endDate
    ) {
      studentId
      guidanceJukuTestTemplateId
      testName
      pathItems {
        folderName
      }
      summary {
        komaDates
        latestTestResult
        retestCount
      }
    }
  }
`;
export const getManagementUserByUsername = /* GraphQL */ `
  query getManagementUserByUsername($username: String!) {
    getManagementUserByUsername(username: $username) {
      __typename
      ... on Teacher {
        id
        userId
        jukuId
        jukuUserId
        managementUserId
        username
        changedPasswordFlag
        name
        furigana
        gender
        createdDate
        position
        memo
        mainClassroom {
          id
          classroomName
        }
        subClassrooms {
          id
          classroomName
        }
      }
      ... on Manager {
        id
        userId
        jukuId
        jukuUserId
        managementUserId
        username
        changedPasswordFlag
        name
        furigana
        gender
        createdDate
        juku {
          classrooms {
            id
            classroomName
          }
        }
      }
    }
  }
`;

export const getTeacher = /* GraphQL */ `
  query getTeacher($id: Int!) {
    getTeacher(id: $id) {
      id
      userId
      jukuId
      jukuUserId
      managementUserId
      username
      changedPasswordFlag
      name
      furigana
      gender
      createdDate
      position
      memo
      teacherTags {
        id
        tagName
        color
        memo
      }
      mainClassroom {
        id
        classroomName
      }
      subClassrooms {
        id
        classroomName
      }
    }
  }
`;

/**
 * 教室ごとの講師を取得
 */
export const getTeachersByClassroomId = /* GraphQL */ `
  query getTeachersByClassroomId($classroomId: Int!) {
    getTeachersByClassroomId(classroomId: $classroomId) {
      id
      userId
      jukuId
      jukuUserId
      managementUserId
      username
      name
      furigana
      gender
      position
      mainClassroom {
        id
        classroomName
      }
      # : 後々変わるかも
      latestLoginInfo {
        currentStatus
        loggedInAt
        loggedOutAt
      }
      teacherTags {
        id
        tagName
        color
        memo
      }
    }
  }
`;

/**
 * 教室取得
 */
export const getClassroom = /* GraphQL */ `
  query getClassroom($id: Int!) {
    getClassroom(id: $id) {
      id
      jukuId
      classroomName
      address
      teachers {
        id
        userId
        jukuId
        jukuUserId
        managementUserId
        name
        furigana
        gender
        position
      }
    }
  }
`;

/**
 * 生徒を取得
 */
export const getStudent = /* GraphQL */ `
  query getStudent($id: Int!) {
    getStudent(id: $id) {
      id
      jukuId
      username
      name
      furigana
      gender
      classroom {
        id
      }
      studentGrade {
        id
        grade
        gradeOrder
      }
      guidancePolicy {
        id
        managementUser {
          __typename
          ... on Teacher {
            id
            name
          }
          ... on Manager {
            id
            name
          }
        }
        guidancePolicy
        updatedDate
      }
      school {
        id
        schoolName
        furigana
      }
      semester {
        id
        semesterName
      }
      studentTags {
        id
        tagName
        color
      }
      latestLoginInfo {
        currentStatus
        loggedInAt
        loggedOutAt
      }
      course {
        color
        courseId
        courseName
      }
      scoreResult {
        scoreResultId
        color
        scoreResultName
      }
      unavailableManagementUsers {
        id
        ... on Teacher {
          managementUserId
        }
        ... on Manager {
          managementUserId
        }
        name
      }
    }
  }
`;

/**
 * 教室ごとの生徒を取得
 */
export const getStudentsByClassroomId = /* GraphQL */ `
  query getStudentsByClassroomId($classroomId: Int!) {
    getStudentsByClassroomId(classroomId: $classroomId) {
      id
      username
      name
      furigana
      gender
      createdDate
      latestLoginInfo {
        currentStatus
        loggedInAt
        loggedOutAt
      }
      studentGrade {
        id
        grade
        gradeOrder
      }
      school {
        id
        schoolName
        furigana
      }
      studentTags {
        id
        tagName
        color
        memo
      }
      course {
        color
        courseId
        courseName
      }
      scoreResult {
        scoreResultId
        color
        scoreResultName
      }
    }
  }
`;

/**
 * コマ未登録入室生徒取得
 */
export const getStudentsNotScheduled = /* GraphQL */ `
  query getStudentsNotScheduled($komaDate: String!, $classroomId: Int!) {
    getStudentsNotScheduled(komaDate: $komaDate, classroomId: $classroomId) {
      id
      name
      furigana
      gender
      studentGrade {
        id
        grade
      }
      school {
        schoolName
      }
      latestLoginInfo {
        currentStatus
      }
      studentTags {
        id
        tagName
        color
      }
    }
  }
`;

/**
 * タスク取得
 */
export const getTeacherTasksByClassroomId = /* GraphQL */ `
  query getTeacherTasksByClassroomId($classroomId: Int!) {
    getTeacherTasksByClassroomId(classroomId: $classroomId) {
      id
      taskName
      content
      createdDate
      dueDate
      completedDate
      teacherTaskChecks {
        id
        teacherTask {
          id
          taskId
          taskName
        }
        completedDate
      }
    }
  }
`;

/**
 * 指導タスク取得
 */
export const getGuidanceIndividualTasksByGuidanceReportId = /* GraphQL */ `
  query getGuidanceIndividualTasksByGuidanceReportId($guidanceReportId: Int!) {
    getGuidanceIndividualTasksByGuidanceReportId(guidanceReportId: $guidanceReportId) {
      checked
      content
      guidanceIndividualTaskId
    }
  }
`;

/**
 * 講師タスク取得
 */
export const getTeacherTask = /* GraphQL */ `
  query getTeacherTask($id: Int!) {
    getTeacherTask(id: $id) {
      id
      taskId
      taskName
      content
      createdDate
      dueDate
      completedDate
      classroomIds
      createdManagementUser {
        __typename
        ... on Teacher {
          managementUserId
        }
        ... on Manager {
          managementUserId
        }
      }
      teacherTaskChecks {
        id
        teacherTask {
          id
          taskId
          taskName
        }
        student {
          id
          name
          furigana
          gender
          createdDate
          studentGrade {
            id
            grade
          }
          school {
            schoolName
          }
          latestLoginInfo {
            currentStatus
            loggedInAt
            loggedOutAt
          }
          classroom {
            id
            classroomName
          }
        }
        completedDate
      }
    }
  }
`;

export const getStudentTextbooksByStudentId = /* GraphQL */ `
  query getStudentTextbooksByStudentId(
    $studentId: Int!
    $options: TextbookSearchOptions!
  ) {
    getStudentTextbooksByStudentId(studentId: $studentId, options: $options) {
      id
      textbookName
      importantFlag
      studentGrade {
        id
        grade
        gradeOrder
      }
      semester {
        id
        semesterName
      }
      textbookTags {
        id
        tagName
        color
      }
      subject {
        id
        subjectName
        categoryTypes {
          textbook {
            id
          }
        }
        subjectArea {
          id
          subjectAreaName
          subjects {
            id
            subjectName
          }
        }
      }
      # 進捗
      targetLap
      progressUnitName

      totalTextbookStudyRanges {
        startValue
        endValue
      }
      textbookStudyProgressRates {
        lap
        rate
      }
      schoolLessonProgressRate
    }
  }
`;

/**
 * 教科書単体取得
 */
export const getStudentTextbook = /* GraphQL */ `
  query getStudentTextbook($textbookId: Int!) {
    getStudentTextbook(textbookId: $textbookId) {
      id
      textbookName
      importantFlag
      progressUnitName
      targetLap
      studentGrade {
        id
        grade
        gradeOrder
      }
      semester {
        id
        semesterName
      }
      subject {
        id
        subjectName
        categoryTypes {
          textbook {
            id
          }
        }
        subjectArea {
          id
          subjectAreaName
        }
      }
      textbookTags {
        id
        tagName
        color
      }
      totalTextbookStudyRanges {
        startValue
        endValue
      }

      totalStudyMinutes

      textbookStudyComposition {
        totalTextbookStudy {
          id
          studentTextbookId
          totalTextbookStudyPeriods {
            startDate
            endDate
          }
          totalTextbookProgress {
            lap0
            lap1
            lap2
            lap3
          }
        }

        weeklyTextbookStudies {
          id
          startDate
          endDate
          weeklyTextbookStudyProgressesUnitRanges {
            startValue
            endValue
          }
          weeklyTextbookStudyPlansUnitRanges {
            startValue
            endValue
          }
        }

        guidanceKomaTextbookStudies {
          id
          studentTextbook {
            id
          }
          guidanceReport {
            id
            guidanceKoma {
              id
              komaTemplate {
                komaName
              }
              komaDate
            }
            completedDate
          }
          studyMinutes
          guidanceProgress {
            lap0
            lap1
            lap2
            lap3
          }
        }
      }

      schoolLessonProgress {
        finished
        yet
      }
    }
  }
`;

/**
 * 教材テンプレート取得
 */
export const getTextbookTemplatesBySchoolId = /* GraphQL */ `
  query getTextbookTemplatesBySchoolId($schoolId: Int!) {
    getTextbookTemplatesBySchoolId(schoolId: $schoolId) {
      id
      textbookId
      textbookName
      importantFlag
      progressUnitName
      targetLap
      textbookTags {
        id
        tagName
        color
      }
      subject {
        id
        subjectName
        categoryTypes {
          textbook {
            id
          }
        }
        subjectArea {
          id
          subjectAreaName
        }
      }
      studentGrades {
        id
        grade
      }
    }
  }
`;

export const getTextbookTemplatesByTextbookTagIds = /* GraphQL */ `
  query getTextbookTemplatesByTextbookTagIds($textbookTagIds: [Int!]!) {
    getTextbookTemplatesByTextbookTagIds(textbookTagIds: $textbookTagIds) {
      id
      textbookId
      textbookName
      importantFlag
      progressUnitName
      targetLap
      textbookTags {
        id
        tagName
        color
      }
      subject {
        id
        subjectName
        categoryTypes {
          textbook {
            id
          }
        }
        subjectArea {
          id
          subjectAreaName
        }
      }
      studentGrades {
        id
        grade
      }
    }
  }
`;

/**
 * 他の生徒の教材取得
 */
export const getStudentTextbooksOfOtherStudents = /* GraphQL */ `
  query getStudentTextbooksOfOtherStudents(
    $studentId: Int!
    $options: TextbookSearchOptions!
  ) {
    getStudentTextbooksOfOtherStudents(studentId: $studentId, options: $options) {
      id
      textbookName
      importantFlag
      studentGrade {
        id
        grade
        gradeOrder
      }
      semester {
        id
        semesterName
      }
      textbookTags {
        id
        tagName
        color
      }
      subject {
        id
        subjectName
        categoryTypes {
          textbook {
            id
          }
        }
        subjectArea {
          id
          subjectAreaName
          subjects {
            id
            subjectName
          }
        }
      }
      # 進捗
      targetLap
      progressUnitName

      schoolLessonProgressRate

      textbookStudyProgressRates {
        lap
        rate
      }

      totalTextbookStudyRanges {
        startValue
        endValue
      }
    }
  }
`;

/**
 * 生徒タグ一覧取得
 */
export const getStudentTagsByJukuId = /* GraphQL */ `
  query getStudentTagsByJukuId($jukuId: Int!) {
    getStudentTagsByJukuId(jukuId: $jukuId) {
      id
      tagName
      jukuId
      color
      memo
    }
  }
`;

export const getTextbookTagsByJukuId = /* GraphQL */ `
  query getTextbookTagsByJukuId($jukuId: Int!) {
    getTextbookTagsByJukuId(jukuId: $jukuId) {
      id
      jukuId
      tagName
      color
      memo
    }
  }
`;

/**
 * 教科書登録時フォームに必要な選択肢をまとめて取得
 */
export const getOptionsForTextbook = /* GraphQL */ `
  query getOptionsForTextbook($jukuId: Int!, $studentId: Int!) {
    getOptionsForTextbook(jukuId: $jukuId, studentId: $studentId) {
      studentGrades {
        id
        grade
        gradeOrder
        semesters {
          id
          semesterName
        }
      }
      subjectAreas {
        id
        subjectAreaName
        subjects {
          id
          subjectName
          categoryTypes {
            textbook {
              id
            }
          }
        }
      }
      textbookTags {
        id
        tagName
        color
        memo
      }
      progressUnitTemplates {
        id
        progressUnitName
        templateOrder
      }
    }
  }
`;

export const getOptionsForGuidanceKoma = /* GraphQL */ `
  query getOptionsForGuidanceKoma($jukuId: Int!, $classroomId: Int!) {
    getOptionsForGuidanceKoma(jukuId: $jukuId, classroomId: $classroomId) {
      studentGrades {
        id
        grade
        gradeOrder
      }
      komaTemplates {
        id
        komaName
      }
      managementUsersGroupedByRole {
        mainTeachers {
          managementUserId
          name
          furigana
        }
        mainClassroomMasters {
          managementUserId
          name
          furigana
        }
        subTeachers {
          managementUserId
          name
          furigana
        }
        subClassroomMasters {
          managementUserId
          name
          furigana
        }
        managers {
          managementUserId
          name
          furigana
        }
      }
      subjectAreas {
        id
        subjectAreaName
        subjects {
          id
          subjectName
          categoryTypes {
            guidance {
              id
            }
          }
        }
      }
    }
  }
`;

export const getClassroomGuidanceDatesByDateRange = /* GraphQL */ `
  query getClassroomGuidanceDatesByDateRange(
    $classroomId: Int!
    $startDate: String!
    $endDate: String!
  ) {
    getClassroomGuidanceDatesByDateRange(
      classroomId: $classroomId
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      classroomKomas {
        komaTemplate {
          id
          komaName
          finishedTime
        }
        transferStartedDate
        transferFinishedDate
      }
    }
  }
`;

export const getGuidanceDatesByStudentId = /* GraphQL */ `
  query getGuidanceDatesByStudentId(
    $studentId: Int!
    $startDate: String!
    $endDate: String!
  ) {
    getGuidanceDatesByStudentId(
      studentId: $studentId
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      loginInfos {
        loggedInAt
        loggedOutAt
      }
      exams {
        __typename
        ... on SchoolExam {
          id
          examName
        }
        ... on OriginalExam {
          id
          examName
        }
      }
      guidanceKomas {
        id
        classroom {
          id
          classroomName
        }
        attendanceStatus
        komaTemplate {
          komaName
          id
        }
        subjects {
          id
          subjectName
          categoryTypes {
            guidance {
              id
            }
          }
          subjectArea {
            id
          }
        }
        managementUser {
          __typename
          ... on Teacher {
            managementUserId
            name
          }
          ... on Manager {
            managementUserId
            name
          }
        }
        transferOriginGuidanceKoma {
          komaDate
          komaTemplate {
            komaName
          }
        }
        transferStartedDate
        transferFinishedDate
      }
    }
  }
`;

export const getSchoolExamByStudentTextbookId = /* GraphQL */ `
  query getSchoolExamByStudentTextbookId($studentTextbookId: Int!) {
    getSchoolExamByStudentTextbookId(studentTextbookId: $studentTextbookId) {
      id
      examName
      examStartDate
      examEndDate
    }
  }
`;

/**
 * 日付ごとの指導報告を取得
 * TODO: 教材スピード 新スキーマ対応
 */
export const getGuidanceReportsByDate = /* GraphQL */ `
  query getGuidanceReportsByDate($studentId: Int!, $date: String!) {
    getGuidanceReportsByDate(studentId: $studentId, date: $date) {
      id
      completedDate
      guidanceLoginInfos {
        loggedInAt
        loggedOutAt
      }
      guidanceKoma {
        id
        attendanceStatus
        komaTemplate {
          komaName
        }
        subjects {
          id
          subjectName
        }
        managementUser {
          __typename
          ... on Teacher {
            id
            username
            name
          }
          ... on Manager {
            id
            username
            name
          }
        }
        classroom {
          id
          classroomName
        }
      }
      guidanceIndividualTasks {
        content
        guidanceIndividualTaskId
        checked
      }
      guidanceReviews {
        id
        score
        guidanceReviewMaster {
          id
          itemName
          perfectScore
        }
      }
      parentReport {
        id
        content
      }
      guidanceTests {
        ... on GuidanceJukuTest {
          id
          testResult
          score
          perfectScore
          guidanceReportId
          retestFlag
          guidanceJukuTestTemplate {
            id
            guidanceJukuTestTemplateFolderId
            testName
            pathItems {
              id
              folderName
              parentFolderId
            }
          }
        }
        ... on GuidanceCustomTest {
          id
          score
          testName
          perfectScore
          guidanceReportId
          subject {
            id
            subjectName
            categoryTypes {
              guidance {
                id
              }
            }
          }
        }
      }
      guidanceKomaTextbookStudies {
        id
        studyMinutes
        studentTextbook {
          id
          totalStudyMinutes
          subject {
            id
            subjectName
            categoryTypes {
              textbook {
                id
              }
            }
            subjectArea {
              id
              subjectAreaName
            }
          }
          importantFlag
          textbookName
          studentGrade {
            id
            grade
            gradeOrder
          }
          semester {
            id
            semesterName
          }
          textbookTags {
            id
            tagName
            color
          }
          # 進捗
          targetLap
          progressUnitName
          # TODO:
          # 全体進捗
          totalTextbookStudyRanges {
            startValue
            endValue
          }
          textbookStudyComposition {
            totalTextbookStudy {
              id
              totalTextbookProgress {
                lap0
                lap1
                lap2
                lap3
              }
              totalTextbookStudyPeriods {
                startDate
                endDate
              }
            }
          }
          schoolLessonProgress {
            finished
            yet
          }
        }
        # コマ進捗
        guidanceProgress {
          lap0
          lap1
          lap2
          lap3
        }
      }
      aimAtReports {
        id
        guidanceReportId
        studyInfoUnion {
          __typename
          # StudyInfoUnion
          ... on LectureStudyInfo {
            studyInfoId
            pauseFlag
            startedDate
            finishedDate
            answerTime
            contents {
              __typename
              # 教科書レクチャーコンテンツ
              ... on TextbookLectureContents {
                id
                contentsId
                textbookContentsId
                contentsName
                path
              }
              # レクチャーコンテンツ
              ... on NormalLectureContents {
                id
                contentsName
                contentsId
                path
              }
            }
          }
          # StudyInfoUnion
          ... on OutputStudyInfo {
            studyInfoId
            pauseFlag
            startedDate
            finishedDate
            answerTime
            startedDate
            finishedDate
            # 正答数
            correctCount
            # 問題数
            questionCount
            exerciseQuestionCount
            # 正答率
            # テストの合計点数
            perfectScore
            # 自分の点数
            totalPoint
            answerTime
            descriptionTime
            leftQuestionCount
            contents {
              __typename
              # 教科書一問一答コンテンツ
              ... on TextbookOnetoContents {
                id
                # なんかとれない
                # normalContentsId
                contentsName
                onetoType
                latestStudyInfo {
                  # 正答数
                  correctCount
                  # 問題数
                  questionCount
                  # 正答率
                  # テストの合計点
                  perfectScore
                  # 自分の点数
                  totalPoint
                }
                path
              }
              #  教科書テストコンテンツ
              ... on TextbookTestContents {
                id
                textbookContentsId
                contentsName
                perfectScore
                resultType
                latestStudyInfo {
                  # 正答数
                  correctCount
                  # 問題数
                  questionCount
                  # 正答率
                  # テストの合計点数
                  perfectScore
                  # 自分の点数
                  totalPoint
                }
                path
              }
              # 一問一答コンテンツ
              ... on NormalOnetoContents {
                id
                normalContentsId
                contentsName
                onetoType
                latestStudyInfo {
                  # 正答数
                  correctCount
                  # 問題数
                  questionCount
                  # 正答率
                  # テストの合計点数
                  perfectScore
                  # 自分の点数
                  totalPoint
                }
                path
              }
              # テストコンテンツ
              ... on NormalTestContents {
                id
                normalContentsId
                contentsName
                resultType
                latestStudyInfo {
                  # 正答数
                  correctCount
                  # 問題数
                  questionCount
                  # 正答率
                  # テストの合計点
                  perfectScore
                  # 自分の点数
                  totalPoint
                }
                path
              }
              # ユーザーテストコンテンツ
              ... on UserTestContents {
                userContentsId
                contentsName
                path
                resultType
                todoInfo {
                  id
                  createdDate
                  completedFlag
                }
                latestStudyInfo {
                  startedDate
                  finishedDate
                  # 正答数
                  correctCount
                  # 問題数
                  questionCount
                  # 正答率
                  # テストの合計点数
                  perfectScore
                  # 自分の点数
                  totalPoint
                  answerTime
                }
              }
              # ユーザー一問一答コンテンツ
              ... on UserOnetoContents {
                userContentsId
                contentsName
                path
                onetoType
                todoInfo {
                  id
                  createdDate
                  completedFlag
                }
                latestStudyInfo {
                  startedDate
                  finishedDate
                  # 正答数
                  correctCount
                  # 問題数
                  questionCount
                  # 正答率
                  perfectScore
                  # テストの合計点
                  totalPoint
                  # 自分の点数
                  answerTime
                }
              }
            }
          }
        }
      }
    }
  }
`;
/**
 * 日付範囲ごとの指導履歴を取得
 */
export const getGuidanceHistoriesByDateRange = /* GraphQL */ `
  query getGuidanceHistoriesByDateRange(
    $studentId: Int!
    $startDate: String!
    $endDate: String!
  ) {
    getGuidanceHistoriesByDateRange(
      studentId: $studentId
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      guidanceDateReportsCompletedStatus
    }
  }
`;

/**
 * タイムラインを取得
 */
export const getGuidanceReportHistoriesByStudentId = /* GraphQL */ `
  query getGuidanceReportHistoriesByStudentId(
    $after: Int
    $first: Int!
    $studentId: Int!
  ) {
    getGuidanceReportHistoriesByStudentId(
      after: $after
      first: $first
      studentId: $studentId
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      guidanceReports {
        id
        completedDate
        guidanceLoginInfos {
          loggedInAt
          loggedOutAt
        }
        guidanceKoma {
          id
          komaDate
          komaTemplate {
            komaName
            komaOrder
          }
        }
        guidanceTests {
          ... on GuidanceJukuTest {
            id
            testResult
            score
            perfectScore
            retestFlag
            guidanceJukuTestTemplate {
              id
              testName
              pathItems {
                id
                folderName
                parentFolderId
              }
            }
          }
          ... on GuidanceCustomTest {
            id
            score
            perfectScore
            testName
            perfectScore
            subject {
              id
              subjectName
            }
          }
        }
        guidanceKomaTextbookStudies {
          id
          studentTextbook {
            id
            subject {
              id
              subjectName
              subjectArea {
                id
                subjectAreaName
              }
            }
            importantFlag
            textbookName
            # 進捗
            targetLap
            progressUnitName
          }
          guidanceProgress {
            lap0
            lap1
            lap2
            lap3
          }
          studyMinutes
        }
        aimAtReports {
          id
          guidanceReportId
          studyInfoUnion {
            __typename
            # StudyInfoUnion
            ... on LectureStudyInfo {
              studyInfoId
              pauseFlag
              startedDate
              finishedDate
              answerTime
              contents {
                __typename
                # 教科書レクチャーコンテンツ
                ... on TextbookLectureContents {
                  id
                  contentsId
                  textbookContentsId
                  contentsName
                  path
                }
                # レクチャーコンテンツ
                ... on NormalLectureContents {
                  id
                  contentsName
                  contentsId
                  path
                }
              }
            }
            # StudyInfoUnion
            ... on OutputStudyInfo {
              studyInfoId
              pauseFlag
              startedDate
              finishedDate
              answerTime
              startedDate
              finishedDate
              # 正答数
              correctCount
              # 問題数
              questionCount
              exerciseQuestionCount
              # 正答率
              # テストの合計点数
              perfectScore
              # 自分の点数
              totalPoint
              answerTime
              descriptionTime
              leftQuestionCount
              contents {
                __typename
                # 教科書一問一答コンテンツ
                ... on TextbookOnetoContents {
                  id
                  # なんかとれない
                  # normalContentsId
                  contentsName
                  onetoType
                  latestStudyInfo {
                    # 正答数
                    correctCount
                    # 問題数
                    questionCount
                    # 正答率
                    # テストの合計点
                    perfectScore
                    # 自分の点数
                    totalPoint
                  }
                  path
                }
                #  教科書テストコンテンツ
                ... on TextbookTestContents {
                  id
                  textbookContentsId
                  contentsName
                  perfectScore
                  resultType
                  latestStudyInfo {
                    # 正答数
                    correctCount
                    # 問題数
                    questionCount
                    # 正答率
                    # テストの合計点数
                    perfectScore
                    # 自分の点数
                    totalPoint
                  }
                  path
                }
                # 一問一答コンテンツ
                ... on NormalOnetoContents {
                  id
                  normalContentsId
                  contentsName
                  onetoType
                  latestStudyInfo {
                    # 正答数
                    correctCount
                    # 問題数
                    questionCount
                    # 正答率
                    # テストの合計点数
                    perfectScore
                    # 自分の点数
                    totalPoint
                  }
                  path
                }
                # テストコンテンツ
                ... on NormalTestContents {
                  id
                  normalContentsId
                  contentsName
                  resultType
                  latestStudyInfo {
                    # 正答数
                    correctCount
                    # 問題数
                    questionCount
                    # 正答率
                    # テストの合計点
                    perfectScore
                    # 自分の点数
                    totalPoint
                  }
                  path
                }
                # ユーザーテストコンテンツ
                ... on UserTestContents {
                  userContentsId
                  contentsName
                  path
                  resultType
                  todoInfo {
                    id
                    createdDate
                    completedFlag
                  }
                  latestStudyInfo {
                    startedDate
                    finishedDate
                    # 正答数
                    correctCount
                    # 問題数
                    questionCount
                    # 正答率
                    # テストの合計点数
                    perfectScore
                    # 自分の点数
                    totalPoint
                    answerTime
                  }
                }
                # ユーザー一問一答コンテンツ
                ... on UserOnetoContents {
                  userContentsId
                  contentsName
                  path
                  onetoType
                  todoInfo {
                    id
                    createdDate
                    completedFlag
                  }
                  latestStudyInfo {
                    startedDate
                    finishedDate
                    # 正答数
                    correctCount
                    # 問題数
                    questionCount
                    # 正答率
                    perfectScore
                    # テストの合計点
                    totalPoint
                    # 自分の点数
                    answerTime
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * 日付範囲ごとの契約を取得
 */
export const getStudentContractsByDateRange = /* GraphQL */ `
  query getStudentContractsByDateRange(
    $studentId: Int!
    $startedDate: String
    $endDate: String
  ) {
    getStudentContractsByDateRange(
      studentId: $studentId
      startedDate: $startedDate
      endDate: $endDate
    ) {
      id
      studentId
      startedDate
      finishedDate
      updatedDate
      contractKomas {
        dayOfWeek
      }
    }
  }
`;

/**
 * 契約取得
 */
export const getStudentContract = /* GraphQL */ `
  query getStudentContract($id: Int!) {
    getStudentContract(id: $id) {
      id
      studentId
      startedDate
      finishedDate
      updatedDate
      contractKomas {
        id
        managementUser {
          ... on Teacher {
            managementUserId
            name
          }
          ... on Manager {
            managementUserId
            name
          }
        }
        komaTemplate {
          id
          komaName
          komaOrder
        }
        classroom {
          id
          classroomName
        }
        studentContractId
        dayOfWeek
        subjects {
          subjectName
          id
          categoryTypes {
            guidance {
              id
            }
          }
          subjectArea {
            id
            subjectAreaName
          }
        }
      }
    }
  }
`;

export const getParentReportTagsByJukuId = /* GraphQL */ `
  query getParentReportTagsByJukuId($jukuId: Int!) {
    getParentReportTagsByJukuId(jukuId: $jukuId) {
      id
      tagName
      contentTemplate
      color
      tagType
    }
  }
`;

export const getOptionsForStudentContractRegistration = /* GraphQL */ `
  query getOptionsForStudentContractRegistration($jukuId: Int!, $classroomId: Int!) {
    getOptionsForStudentContractRegistration(jukuId: $jukuId, classroomId: $classroomId) {
      komaTemplates {
        id
        komaName
        komaOrder
      }
      subjectAreas {
        id
        subjectAreaName
        subjects {
          id
          subjectName
          categoryTypes {
            guidance {
              id
            }
          }
        }
      }
      managementUsersGroupedByRole {
        mainTeachers {
          managementUserId
          name
          furigana
        }
        mainClassroomMasters {
          managementUserId
          name
          furigana
        }
        subTeachers {
          managementUserId
          name
          furigana
        }
        subClassroomMasters {
          managementUserId
          name
          furigana
        }
        managers {
          managementUserId
          name
          furigana
        }
      }
    }
  }
`;

/**
 * 定期試験
 */
export const getSchoolExamsByStudentId = /* GraphQL */ `
  query getSchoolExamsByStudentId(
    $studentId: Int!
    $examSearchOptions: ExamSearchOptions!
  ) {
    getSchoolExamsByStudentId(
      studentId: $studentId
      examSearchOptions: $examSearchOptions
    ) {
      id
      school {
        schoolName
      }
      fiscalYear
      examName
      examStartDate
      examEndDate
      studentGrade {
        id
        grade
      }
      semester {
        id
        semesterName
      }
      # 全科目の得合計と満点合計
      totalScorePoint
      totalPerfectPoint
      # 各科目
      examSubjects {
        ... on DefaultSchoolExamSubject {
          __typename
          schoolExamSubjectId
          schoolScoreSubjectId
          examSubjectInfo {
            id
            displayedSubjectName
          }
          examDate
          examStatus
          # 得点
          scorePoint {
            value
            publicationFlag
          }
          scorePerfectPoint {
            value
            publicationFlag
          }
          scoreTargetPoint {
            value
            publicationFlag
          }
          scoreAveragePoint {
            value
            publicationFlag
          }
          # 評定
          evaluationPoint {
            value
            publicationFlag
          }
          evaluationMaxPoint {
            value
            publicationFlag
          }
          # 順位
          rank {
            value
            publicationFlag
          }
          rankOverall {
            value
            publicationFlag
          }
        }
        ... on AdditionalSchoolExamSubject {
          __typename
          schoolScoreSubjectId
          examSubjectInfo {
            id
            # examSubject
            displayedSubjectName
          }
          examDate
          examStatus
          # 得点
          scorePoint {
            value
            publicationFlag
          }
          scorePerfectPoint {
            value
            publicationFlag
          }
          scoreTargetPoint {
            value
            publicationFlag
          }
          scoreAveragePoint {
            value
            publicationFlag
          }
          # 評定
          evaluationPoint {
            value
            publicationFlag
          }
          evaluationMaxPoint {
            value
            publicationFlag
          }
          # 順位
          rank {
            value
            publicationFlag
          }
          rankOverall {
            value
            publicationFlag
          }
        }
      }
    }
  }
`;
export const getOriginalExamsByStudentId = /* GraphQL */ `
  query getOriginalExamsByStudentId($studentId: Int!) {
    getOriginalExamsByStudentId(studentId: $studentId) {
      id
      examName
      examStartDate
      examEndDate
      studentGrade {
        id
        grade
      }
      semester {
        id
        semesterName
      }
      # 全科目の得合計と満点合計
      totalScorePoint
      totalPerfectPoint
      # 各科目
      examSubjects {
        originalExamSubjectId
        examSubjectInfo {
          id
          # examSubject
          displayedSubjectName
        }
        examDate
        # 得点
        scorePoint {
          value
          publicationFlag
        }
        scorePerfectPoint {
          value
          publicationFlag
        }
        scoreTargetPoint {
          value
          publicationFlag
        }
        scoreAveragePoint {
          value
          publicationFlag
        }
        # 評定
        evaluationPoint {
          value
          publicationFlag
        }
        evaluationMaxPoint {
          value
          publicationFlag
        }
        # 順位
        rank {
          value
          publicationFlag
        }
        rankOverall {
          value
          publicationFlag
        }
      }
    }
  }
`;
export const getSchoolExamById = /* GraphQL */ `
  query getSchoolExamById($id: Int!, $studentId: Int!) {
    getSchoolExamById(id: $id, studentId: $studentId) {
      id
      fiscalYear
      examName
      examStartDate
      examEndDate
      studentGrade {
        id
        grade
      }
      semester {
        id
        semesterName
      }
      # 全科目の得合計と満点合計
      totalScorePoint
      totalPerfectPoint
      # 各科目
      examSubjects {
        __typename
        ... on DefaultSchoolExamSubject {
          schoolExamSubjectId
          schoolScoreSubjectId
          examDate
          examStatus
          examSubjectInfo {
            id
            subject {
              id
              subjectName
              categoryTypes {
                exam {
                  id
                }
              }
              subjectArea {
                subjectAreaName
              }
            }
            displayedSubjectName
          }
          # 得点
          scorePoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          scorePerfectPoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          scoreTargetPoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          scoreAveragePoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          # 評定
          evaluationPoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          evaluationMaxPoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          # 順位
          rank {
            value
            publicationFlag
            onlyVerbalCheck
          }
          rankOverall {
            value
            publicationFlag
            onlyVerbalCheck
          }
        }
        ... on AdditionalSchoolExamSubject {
          schoolScoreSubjectId
          examDate
          examStatus
          examSubjectInfo {
            id
            subject {
              id
              subjectName
              categoryTypes {
                exam {
                  id
                }
              }
            }
            displayedSubjectName
          }
          # 得点
          scorePoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          scorePerfectPoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          scoreTargetPoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          scoreAveragePoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          # 評定
          evaluationPoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          evaluationMaxPoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          # 順位
          rank {
            value
            publicationFlag
            onlyVerbalCheck
          }
          rankOverall {
            value
            publicationFlag
            onlyVerbalCheck
          }
        }
      }
    }
  }
`;

export const getOriginalExamById = /* GraphQL */ `
  query getOriginalExamById($id: Int!) {
    getOriginalExamById(id: $id) {
      id
      examName
      examStartDate
      examEndDate
      studentGrade {
        id
        grade
      }
      semester {
        id
        semesterName
      }
      # 全科目の得合計と満点合計
      totalScorePoint
      totalPerfectPoint
      # 各科目
      examSubjects {
        originalExamSubjectId
        examSubjectInfo {
          id
          subject {
            id
            subjectName
            categoryTypes {
              exam {
                id
              }
            }
          }
          displayedSubjectName
        }
        examDate
        examStatus
        # 得点
        scorePoint {
          value
          publicationFlag
          onlyVerbalCheck
        }
        scorePerfectPoint {
          value
          publicationFlag
          onlyVerbalCheck
        }
        scoreTargetPoint {
          value
          publicationFlag
          onlyVerbalCheck
        }
        scoreAveragePoint {
          value
          publicationFlag
          onlyVerbalCheck
        }
        # 評定
        evaluationPoint {
          value
          publicationFlag
          onlyVerbalCheck
        }
        evaluationMaxPoint {
          value
          publicationFlag
          onlyVerbalCheck
        }
        # 順位
        rank {
          value
          publicationFlag
          onlyVerbalCheck
        }
        rankOverall {
          value
          publicationFlag
          onlyVerbalCheck
        }
      }
    }
  }
`;

// 選択肢取得
export const getOptionsForExam = /* GraphQL */ `
  query getOptionsForExam($jukuId: Int!, $studentId: Int!) {
    getOptionsForExam(jukuId: $jukuId, studentId: $studentId) {
      studentGrades {
        id
        grade
        gradeOrder
      }
      semesters {
        id
        semesterName
      }
      subjectAreas {
        id
        subjectAreaName
        subjects {
          id
          subjectName
          categoryTypes {
            exam {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * 成績遷移のための学校の定期試験一覧を取得
 */
export const getSchoolExamSubjectsForScoreTrend = /* GraphQL */ `
  query getSchoolExamSubjectsForScoreTrend(
    $studentId: Int!
    $studentGradeId: Int!
    $fiscalYear: Int!
  ) {
    getSchoolExamSubjectsForScoreTrend(
      studentId: $studentId
      studentGradeId: $studentGradeId
      fiscalYear: $fiscalYear
    ) {
      __typename
      ... on DefaultSchoolExamSubjectForScoreTrend {
        # schoolExamId
        # schoolScoreSubjectId
        examSubjectInfo {
          id
          displayedSubjectName
          subject {
            id
            subjectName
          }
        }
        # examDate
        semesterScores {
          examStatus
          schoolExam {
            id
            examName
          }
          # 得点
          scorePoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          scorePerfectPoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          scoreTargetPoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          scoreAveragePoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          # 評定
          evaluationPoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          evaluationMaxPoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          # 順位
          rank {
            value
            publicationFlag
            onlyVerbalCheck
          }
          rankOverall {
            value
            publicationFlag
            onlyVerbalCheck
          }
        }
      }
      ... on AdditionalSchoolExamSubjectForScoreTrend {
        # schoolExamId
        # schoolScoreSubjectId
        examSubjectInfo {
          id
          displayedSubjectName
          subject {
            id
            subjectName
          }
        }
        # examDate
        semesterScores {
          examStatus
          schoolExam {
            id
            examName
          }
          # 得点
          scorePoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          scorePerfectPoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          scoreTargetPoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          scoreAveragePoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          # 評定
          evaluationPoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          evaluationMaxPoint {
            value
            publicationFlag
            onlyVerbalCheck
          }
          # 順位
          rank {
            value
            publicationFlag
            onlyVerbalCheck
          }
          rankOverall {
            value
            publicationFlag
            onlyVerbalCheck
          }
        }
      }
    }
  }
`;

/**
 * 日付とクラスルームごとの指導コマを取得
 */
export const getGuidanceKomasByClassroomByDate = /* GraphQL */ `
  query getGuidanceKomasByClassroomByDate($classroomId: Int!, $komaDate: String!) {
    getGuidanceKomasByClassroomByDate(classroomId: $classroomId, komaDate: $komaDate) {
      id
      komaDate
      attendanceStatus
      komaTemplate {
        id
        komaName
        komaOrder
      }
      student {
        id
        name
        username
        furigana
        gender
        studentGrade {
          id
          grade
        }
        school {
          schoolName
        }
        latestLoginInfo {
          currentStatus
        }
        studentTags {
          id
          tagName
          color
        }
        course {
          courseId
          courseName
          color
        }
        scoreResult {
          color
          scoreResultId
          scoreResultName
        }
      }
      guidanceReport {
        id
        completedDate
        guidanceIndividualTasks {
          content
          guidanceIndividualTaskId
          checked
        }
      }
      subjects {
        id
        subjectName
        subjectArea {
          id
        }
      }
      managementUser {
        __typename
        ... on Teacher {
          id
          managementUserId
          name
          username
          furigana
        }
        ... on Manager {
          id
          managementUserId
          name
          username
          furigana
        }
      }
    }
  }
`;

/**
 * 講師シフト取得
 */
// 複数講師のシフト一覧取得
export const getTeacherShiftsByClassroomIdAndDateRange = /* GraphQL */ `
  query getTeacherShiftsByClassroomIdAndDateRange(
    $classroomId: Int!
    $startDate: String!
    $endDate: String!
  ) {
    getTeacherShiftsByClassroomIdAndDateRange(
      classroomId: $classroomId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      managementUser {
        __typename
        ... on Teacher {
          name
          id
        }
        ... on Manager {
          name
          id
        }
      }
      shiftDate
      teacherShiftKomas {
        id
        teacherShiftId
        komaTemplate {
          id
          komaName
          komaOrder
        }
        attendanceStatus
        subjects {
          id
          subjectName
          subjectArea {
            id
            subjectAreaName
          }
        }
        classroom {
          id
          classroomName
        }
      }
    }
  }
`;

// 一講師のシフト一覧取得
export const getTeacherShiftsByManagementUserIdAndDateRange = /* GraphQL */ `
  query getTeacherShiftsByManagementUserIdAndDateRange(
    $managementUserId: Int!
    $startDate: String!
    $endDate: String!
  ) {
    getTeacherShiftsByManagementUserIdAndDateRange(
      managementUserId: $managementUserId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      managementUser {
        __typename
        ... on Teacher {
          username
          id
        }
        ... on Manager {
          username
          id
        }
      }
      shiftDate
      teacherShiftKomas {
        id
        teacherShiftId
        komaTemplate {
          id
          komaName
          komaOrder
        }
        attendanceStatus
        subjects {
          id
          subjectName
          subjectArea {
            id
            subjectAreaName
          }
          categoryTypes {
            guidance {
              id
            }
          }
        }
        classroom {
          id
          classroomName
        }
      }
    }
  }
`;

// 1講師のシフト単体取得
export const getTeacherShiftByManagementUserIdAndDate = /* GraphQL */ `
  query getTeacherShiftByManagementUserIdAndDate(
    $managementUserId: Int!
    $date: String!
  ) {
    getTeacherShiftByManagementUserIdAndDate(
      managementUserId: $managementUserId
      date: $date
    ) {
      managementUser {
        ... on Manager {
          id
        }
        ... on Teacher {
          id
        }
      }
      shiftDate
      teacherShiftKomas {
        id
        komaTemplate {
          id
          komaName
          komaOrder
        }
        attendanceStatus
        subjects {
          id
          subjectName
          subjectArea {
            id
            subjectAreaName
          }
          categoryTypes {
            guidance {
              id
            }
          }
        }
        classroom {
          id
          classroomName
        }
      }
    }
  }
`;
// 選択肢取得
export const getOptionsForTeacherShiftKoma = /* GraphQL */ `
  query getOptionsForTeacherShiftKoma($jukuId: Int!, $classroomId: Int!) {
    getOptionsForTeacherShiftKoma(jukuId: $jukuId, classroomId: $classroomId) {
      komaTemplates {
        id
        komaName
        komaOrder
      }
      subjectAreas {
        id
        subjectAreaName
        subjects {
          id
          subjectName
          categoryTypes {
            guidance {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 *
 * 講師固定シフト
 *
 */
export const getFixedTeacherShiftKomas = /* GraphQL */ `
  query getFixedTeacherShiftKomas($managementUserId: Int!) {
    getFixedTeacherShiftKomas(managementUserId: $managementUserId) {
      id
      dayOfWeek
      komaTemplate {
        id
        komaName
        komaOrder
      }
      managementUser {
        ... on Manager {
          managementUserId
          name
        }
        ... on Teacher {
          managementUserId
          name
        }
      }
      classroom {
        id
        classroomName
      }
      subjects {
        id
        subjectArea {
          id
        }
        categoryTypes {
          guidance {
            id
          }
        }
        subjectName
      }
    }
  }
`;

export const getKomaTemplatesByJukuId = /* GraphQL */ `
  query getKomaTemplatesByJukuId($jukuId: Int!) {
    getKomaTemplatesByJukuId(jukuId: $jukuId) {
      id
      komaName
      komaOrder
    }
  }
`;

export const getKomaTemplatesByClassroomId = /* GraphQL */ `
  query getKomaTemplatesByClassroomId($classroomId: Int!) {
    getKomaTemplatesByClassroomId(classroomId: $classroomId) {
      id
      komaName
      komaOrder
    }
  }
`;

export const getTeacherSharedCommentsByStudentId = /* GraphQL */ `
  query getTeacherSharedCommentsByStudentId($studentId: Int!) {
    getTeacherSharedCommentsByStudentId(studentId: $studentId) {
      id
      studentId
      managementUser {
        __typename
        ... on Teacher {
          managementUserId
          username
          name
        }
        ... on Manager {
          managementUserId
          username
          name
        }
      }
      comment
      createdDate #date
      pinDate #date
      teacherSharedCommentReactions {
        id
        managementUser {
          __typename
          ... on Teacher {
            managementUserId
            username
            name
          }
          ... on Manager {
            managementUserId
            username
            name
          }
        }
        reaction
      }
      teacherSharedCommentReads {
        id
        managementUser {
          __typename
          ... on Teacher {
            managementUserId
            username
          }
          ... on Manager {
            managementUserId
            username
          }
        }
        readDate #date
      }
    }
  }
`;

// aimの学習履歴の情報
export const getStudyHistoriesByStudentId = /* GraphQL */ `
  query getStudyHistoriesByStudentId($after: Int, $first: Int!, $studentId: Int!) {
    getStudyHistoriesByStudentId(after: $after, first: $first, studentId: $studentId) {
      pageInfo {
        endCursor
        hasNextPage
      }
      studyHistories {
        __typename
        # StudyInfoUnion
        ... on LectureStudyInfo {
          studyInfoId
          pauseFlag
          startedDate
          finishedDate
          answerTime
          contents {
            __typename
            # 教科書レクチャーコンテンツ
            ... on TextbookLectureContents {
              id
              contentsId
              textbookContentsId
              contentsName
              path
            }
            # レクチャーコンテンツ
            ... on NormalLectureContents {
              id
              contentsName
              contentsId
              path
            }
          }
        }
        # StudyInfoUnion
        ... on OutputStudyInfo {
          studyInfoId
          pauseFlag
          startedDate
          finishedDate
          answerTime
          startedDate
          finishedDate
          # 正答数
          correctCount
          # 問題数
          questionCount
          exerciseQuestionCount
          # 正答率
          # テストの合計点数
          perfectScore
          # 自分の点数
          totalPoint
          answerTime
          descriptionTime
          leftQuestionCount
          contents {
            __typename
            # 教科書一問一答コンテンツ
            ... on TextbookOnetoContents {
              id
              # なんかとれない
              # normalContentsId
              contentsName
              onetoType
              path
            }
            #  教科書テストコンテンツ
            ... on TextbookTestContents {
              id
              textbookContentsId
              contentsName
              perfectScore
              resultType
              path
              perfectScore
            }
            # 一問一答コンテンツ
            ... on NormalOnetoContents {
              id
              normalContentsId
              contentsName
              onetoType
              path
            }
            # テストコンテンツ
            ... on NormalTestContents {
              id
              normalContentsId
              contentsName
              resultType
              path
              perfectScore
            }
            # ユーザーテストコンテンツ
            ... on UserTestContents {
              userContentsId
              contentsName
              path
              resultType
              todoInfo {
                id
                createdDate
                completedFlag
              }
            }
            # ユーザー一問一答コンテンツ
            ... on UserOnetoContents {
              userContentsId
              contentsName
              path
              onetoType
              todoInfo {
                id
                createdDate
                completedFlag
              }
            }
          }
        }
      }
    }
  }
`;

// 指導報告aim 学習履歴一覧取得(日単位)
export const getStudyHistoriesByStudentIdAndDate = /* GraphQL */ `
  query getStudyHistoriesByStudentIdAndDate($studentId: Int!, $date: String!) {
    getStudyHistoriesByStudentIdAndDate(studentId: $studentId, date: $date) {
      __typename
      # StudyInfoUnion
      ... on LectureStudyInfo {
        studyInfoId
        pauseFlag
        startedDate
        finishedDate
        answerTime
        contents {
          __typename
          # 教科書レクチャーコンテンツ
          ... on TextbookLectureContents {
            id
            contentsId
            textbookContentsId
            contentsName
            path
          }
          # レクチャーコンテンツ
          ... on NormalLectureContents {
            id
            contentsName
            contentsId
            path
          }
        }
      }
      # StudyInfoUnion
      ... on OutputStudyInfo {
        studyInfoId
        pauseFlag
        startedDate
        finishedDate
        answerTime
        startedDate
        finishedDate
        # 正答数
        correctCount
        # 問題数
        questionCount
        exerciseQuestionCount
        # 正答率
        # テストの合計点数
        perfectScore
        # 自分の点数
        totalPoint
        answerTime
        descriptionTime
        leftQuestionCount
        contents {
          __typename
          # 教科書一問一答コンテンツ
          ... on TextbookOnetoContents {
            id
            # なんかとれない
            # normalContentsId
            contentsName
            onetoType
            latestStudyInfo {
              # 正答数
              correctCount
              # 問題数
              questionCount
              # 正答率
              # テストの合計点
              perfectScore
              # 自分の点数
              totalPoint
            }
            path
          }
          #  教科書テストコンテンツ
          ... on TextbookTestContents {
            id
            textbookContentsId
            contentsName
            perfectScore
            resultType
            latestStudyInfo {
              # 正答数
              correctCount
              # 問題数
              questionCount
              # 正答率
              # テストの合計点数
              perfectScore
              # 自分の点数
              totalPoint
            }
            path
          }
          # 一問一答コンテンツ
          ... on NormalOnetoContents {
            id
            normalContentsId
            contentsName
            onetoType
            latestStudyInfo {
              # 正答数
              correctCount
              # 問題数
              questionCount
              # 正答率
              # テストの合計点数
              perfectScore
              # 自分の点数
              totalPoint
            }
            path
          }
          # テストコンテンツ
          ... on NormalTestContents {
            id
            normalContentsId
            contentsName
            resultType
            latestStudyInfo {
              # 正答数
              correctCount
              # 問題数
              questionCount
              # 正答率
              # テストの合計点
              perfectScore
              # 自分の点数
              totalPoint
            }
            path
          }
          # ユーザーテストコンテンツ
          ... on UserTestContents {
            userContentsId
            contentsName
            path
            resultType
            todoInfo {
              id
              createdDate
              completedFlag
            }
            latestStudyInfo {
              startedDate
              finishedDate
              # 正答数
              correctCount
              # 問題数
              questionCount
              # 正答率
              # テストの合計点数
              perfectScore
              # 自分の点数
              totalPoint
              answerTime
            }
          }
          # ユーザー一問一答コンテンツ
          ... on UserOnetoContents {
            userContentsId
            contentsName
            path
            onetoType
            todoInfo {
              id
              createdDate
              completedFlag
            }
            latestStudyInfo {
              startedDate
              finishedDate
              # 正答数
              correctCount
              # 問題数
              questionCount
              # 正答率
              perfectScore
              # テストの合計点
              totalPoint
              # 自分の点数
              answerTime
            }
          }
        }
      }
    }
  }
`;

export const getGuidanceReviewMastersByJukuId = /* GraphQL */ `
  query getGuidanceReviewMastersByJukuId($jukuId: Int!) {
    getGuidanceReviewMastersByJukuId(jukuId: $jukuId) {
      id
      jukuId
      itemName
      perfectScore
      masterOrder
      scores {
        score
      }
    }
  }
`;
// aimのtodo進捗履歴の情報
export const getTodoProgressesByStudentId = /* GraphQL */ `
  query getTodoProgressesByStudentId($studentId: Int!) {
    getTodoProgressesByStudentId(studentId: $studentId) {
      __typename
      ... on TextbookLectureContents {
        id
        contentsId
        textbookContentsId
        contentsName
        latestStudyInfo {
          startedDate
          finishedDate
          answerTime
          pauseFlag
        }
        todoInfo {
          id
          createdDate
          completedFlag
        }
        path
      }
      ... on NormalLectureContents {
        id
        contentsName
        contentsId
        latestStudyInfo {
          startedDate
          finishedDate
          answerTime
          pauseFlag
        }
        todoInfo {
          id
          createdDate
          completedFlag
        }
        path
      }
      ... on NormalOnetoContents {
        id
        normalContentsId
        contentsName
        onetoType
        todoInfo {
          id
          createdDate
          completedFlag
        }
        latestStudyInfo {
          startedDate
          finishedDate
          # 問題数
          questionCount
          exerciseQuestionCount
          # 正答数
          correctCount
          # 正答率
          # テストの合計点
          perfectScore
          # 自分の点数
          totalPoint
          answerTime
          descriptionTime
          leftQuestionCount
        }
        path
      }
      ... on TextbookOnetoContents {
        id
        # なんかとれない
        # normalContentsId
        contentsName
        onetoType
        todoInfo {
          id
          createdDate
          completedFlag
        }
        latestStudyInfo {
          startedDate
          finishedDate
          # 問題数
          questionCount
          exerciseQuestionCount
          # 正答数
          correctCount
          # 正答率
          # テストの合計点
          perfectScore
          # 自分の点数
          totalPoint
          answerTime
          descriptionTime
          leftQuestionCount
        }
        path
      }
      ... on NormalTestContents {
        id
        normalContentsId
        contentsName
        resultType
        # テストの合計点
        perfectScore
        todoInfo {
          id
          createdDate
          completedFlag
        }
        latestStudyInfo {
          startedDate
          finishedDate
          # 問題数
          questionCount
          exerciseQuestionCount
          # 正答数
          correctCount
          # 正答率
          # 自分の点数
          totalPoint
          answerTime
          descriptionTime
          leftQuestionCount
        }
        path
      }
      ... on TextbookTestContents {
        id
        textbookContentsId
        contentsName
        perfectScore
        resultType
        # 正答率
        # テストの合計点数
        perfectScore
        todoInfo {
          id
          createdDate
          completedFlag
        }
        latestStudyInfo {
          startedDate
          finishedDate
          # 問題数
          questionCount
          exerciseQuestionCount
          # 正答数
          correctCount
          # 自分の点数
          totalPoint
          answerTime
          descriptionTime
          leftQuestionCount
        }
        path
      }
      ... on UserTestContents {
        userContentsId
        contentsName
        path
        resultType
        todoInfo {
          id
          createdDate
          completedFlag
        }
        latestStudyInfo {
          startedDate
          finishedDate
          # 問題数
          questionCount
          exerciseQuestionCount
          # 正答数
          correctCount
          # 正答率
          # テストの合計点数
          perfectScore
          # 自分の点数
          totalPoint
          answerTime
          descriptionTime
          leftQuestionCount
        }
      }
      ... on UserOnetoContents {
        userContentsId
        contentsName
        path
        onetoType
        todoInfo {
          id
          createdDate
          completedFlag
        }
        latestStudyInfo {
          startedDate
          finishedDate
          # 問題数
          questionCount
          exerciseQuestionCount
          # 正答数
          correctCount
          # 正答率
          perfectScore
          # テストの合計点
          totalPoint
          # 自分の点数
          answerTime
          descriptionTime
          leftQuestionCount
        }
      }
    }
  }
`;

// 生徒詳細の講師タスク詳細ページ
export const getTeacherTaskCheck = /* GraphQL */ `
  query getTeacherTaskCheck($id: Int!) {
    getTeacherTaskCheck(id: $id) {
      id
      completedDate
      teacherTask {
        id
        taskId
        taskName
        content
        createdDate
        dueDate
      }
    }
  }
`;

// 生徒詳細の講師タスク一覧ページ
export const getTeacherTaskChecksByStudentId = /* GraphQL */ `
  query getTeacherTaskChecksByStudentId($studentId: Int!) {
    getTeacherTaskChecksByStudentId(studentId: $studentId) {
      id
      completedDate
      teacherTask {
        id
        taskId
        taskName
        content
        createdDate
        dueDate
      }
    }
  }
`;

/**
 * 試験までの日数/コマ数取得
 */
export const getLeftKomaInfo = /* GraphQL */ `
  query getLeftKomaInfo($schoolExamId: Int!, $studentId: Int!) {
    getLeftKomaInfo(schoolExamId: $schoolExamId, studentId: $studentId) {
      leftDayCounts
      leftKomaCounts
    }
  }
`;
export const getOptionForLeftKomaInfo = /* GraphQL */ `
  query getOptionForLeftKomaInfo(
    $studentId: Int!
    $examSearchOptions: ExamSearchOptions!
  ) {
    getOptionForLeftKomaInfo(
      studentId: $studentId
      examSearchOptions: $examSearchOptions
    ) {
      schoolExams {
        id
        examName
        semester {
          id
        }
      }
      nextExam {
        id
        examName
        semester {
          id
        }
      }
    }
  }
`;

export const getOptionsForGuidanceKomaTextbookStudy = /* GraphQL */ `
  query getOptionsForGuidanceKomaTextbookStudy(
    $studentId: Int!
    $currentManagementUserId: Int!
    $date: String!
  ) {
    getOptionsForGuidanceKomaTextbookStudy(
      studentId: $studentId
      currentManagementUserId: $currentManagementUserId
      date: $date
    ) {
      guidanceKomas {
        id
        komaTemplate {
          id
          komaName
          komaOrder
        }
        guidanceReport {
          id
          guidanceKomaTextbookStudies {
            id
            studyMinutes
            studentTextbook {
              id
            }
            guidanceProgress {
              lap0
              lap1
              lap2
              lap3
            }
          }
          completedDate
        }
        komaDate
      }
    }
  }
`;

export const getGuidanceJukuTestTemplateTopFolders = /* GraphQL */ `
  query getGuidanceJukuTestTemplateTopFolders($jukuId: Int!) {
    getGuidanceJukuTestTemplateTopFolders(jukuId: $jukuId) {
      id
      jukuId
      folderInfo {
        id
        folderName
      }
    }
  }
`;

export const getGuidanceJukuTestTemplateFolderChildren = /* GraphQL */ `
  query getGuidanceJukuTestTemplateFolderChildren($parentFolderId: Int!) {
    getGuidanceJukuTestTemplateFolderChildren(parentFolderId: $parentFolderId) {
      id
      folderName
    }
  }
`;

export const getGuidanceJukuTestTemplate = /* GraphQL */ `
  query getGuidanceJukuTestTemplate($guidanceJukuTestTemplateFolderId: Int!) {
    getGuidanceJukuTestTemplate(
      guidanceJukuTestTemplateFolderId: $guidanceJukuTestTemplateFolderId
    ) {
      id
      guidanceJukuTestTemplateFolderId
      testName
      perfectScore
      pathItems {
        id
        folderName
        parentFolderId
      }
    }
  }
`;

export const getContractedKomaSubjectsByDateRange = /* GraphQL */ `
  query getContractedKomaSubjectsByDateRange(
    $studentId: Int!
    $dateRanges: [GetContractedKomaSubjectsByDateRangeArgsDateRange!]!
  ) {
    getContractedKomaSubjectsByDateRange(studentId: $studentId, dateRanges: $dateRanges) {
      id
      subjectName
      subjectArea {
        id
        subjectAreaName
      }
      categoryTypes {
        textbook {
          id
        }
        guidance {
          id
        }
        exam {
          id
        }
      }
    }
  }
`;

/**
 *
 * 指導タスクの移動のため候補の指導コマを取得
 *
 */
export const getGuidanceDatesForBulkUpdateGuidanceIndividualTasks = /* GraphQL */ `
  query getGuidanceDatesForBulkUpdateGuidanceIndividualTasks(
    $studentId: Int!
    $startDate: String!
    $endDate: String!
  ) {
    getGuidanceDatesByStudentId(
      studentId: $studentId
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      guidanceKomas {
        id
        attendanceStatus
        komaTemplate {
          komaName
        }
        guidanceReport {
          id
        }
      }
    }
  }
`;
