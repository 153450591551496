import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
export var BaseCard = function BaseCard() {
  return import('../../src/shared/components/BaseCard.vue' /* webpackChunkName: "components/base-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Circular = function Circular() {
  return import('../../src/shared/components/Circular.vue' /* webpackChunkName: "components/circular" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FooterTab = function FooterTab() {
  return import('../../src/shared/components/FooterTab.vue' /* webpackChunkName: "components/footer-tab" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Loading = function Loading() {
  return import('../../src/shared/components/Loading.vue' /* webpackChunkName: "components/loading" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Logo = function Logo() {
  return import('../../src/shared/components/Logo.vue' /* webpackChunkName: "components/logo" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var NotificationSnackBar = function NotificationSnackBar() {
  return import('../../src/shared/components/NotificationSnackBar.vue' /* webpackChunkName: "components/notification-snack-bar" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Tag = function Tag() {
  return import('../../src/shared/components/Tag.vue' /* webpackChunkName: "components/tag" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextMarkedLeft = function TextMarkedLeft() {
  return import('../../src/shared/components/TextMarkedLeft.vue' /* webpackChunkName: "components/text-marked-left" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var IconTextBtn = function IconTextBtn() {
  return import('../../src/shared/components/buttons/IconTextBtn.vue' /* webpackChunkName: "components/icon-text-btn" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PreviousBtn = function PreviousBtn() {
  return import('../../src/shared/components/buttons/PreviousBtn.vue' /* webpackChunkName: "components/previous-btn" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var RoundedBtn = function RoundedBtn() {
  return import('../../src/shared/components/buttons/RoundedBtn.vue' /* webpackChunkName: "components/rounded-btn" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SwitchBtn = function SwitchBtn() {
  return import('../../src/shared/components/buttons/SwitchBtn.vue' /* webpackChunkName: "components/switch-btn" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherSharedCommentReactionBtn = function TeacherSharedCommentReactionBtn() {
  return import('../../src/shared/components/buttons/TeacherSharedCommentReactionBtn.vue' /* webpackChunkName: "components/teacher-shared-comment-reaction-btn" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextBtn = function TextBtn() {
  return import('../../src/shared/components/buttons/TextBtn.vue' /* webpackChunkName: "components/text-btn" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ConfirmationDialog = function ConfirmationDialog() {
  return import('../../src/shared/components/dialogs/ConfirmationDialog.vue' /* webpackChunkName: "components/confirmation-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ErrorDialog = function ErrorDialog() {
  return import('../../src/shared/components/dialogs/ErrorDialog.vue' /* webpackChunkName: "components/error-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PlainDialog = function PlainDialog() {
  return import('../../src/shared/components/dialogs/PlainDialog.vue' /* webpackChunkName: "components/plain-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ComboboxInput = function ComboboxInput() {
  return import('../../src/shared/components/inputs/ComboboxInput.vue' /* webpackChunkName: "components/combobox-input" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DatePicker = function DatePicker() {
  return import('../../src/shared/components/inputs/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DatePickerInput = function DatePickerInput() {
  return import('../../src/shared/components/inputs/DatePickerInput.vue' /* webpackChunkName: "components/date-picker-input" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var MultiSubjectsSelectInputs = function MultiSubjectsSelectInputs() {
  return import('../../src/shared/components/inputs/MultiSubjectsSelectInputs.vue' /* webpackChunkName: "components/multi-subjects-select-inputs" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var RadioInput = function RadioInput() {
  return import('../../src/shared/components/inputs/RadioInput.vue' /* webpackChunkName: "components/radio-input" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var RoundedMultipleSelect = function RoundedMultipleSelect() {
  return import('../../src/shared/components/inputs/RoundedMultipleSelect.vue' /* webpackChunkName: "components/rounded-multiple-select" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var RoundedSelect = function RoundedSelect() {
  return import('../../src/shared/components/inputs/RoundedSelect.vue' /* webpackChunkName: "components/rounded-select" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SubjectSelectInputs = function SubjectSelectInputs() {
  return import('../../src/shared/components/inputs/SubjectSelectInputs.vue' /* webpackChunkName: "components/subject-select-inputs" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextInput = function TextInput() {
  return import('../../src/shared/components/inputs/TextInput.vue' /* webpackChunkName: "components/text-input" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextareaInput = function TextareaInput() {
  return import('../../src/shared/components/inputs/TextareaInput.vue' /* webpackChunkName: "components/textarea-input" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextareaReadOnly = function TextareaReadOnly() {
  return import('../../src/shared/components/inputs/TextareaReadOnly.vue' /* webpackChunkName: "components/textarea-read-only" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AttendanceStatusLabel = function AttendanceStatusLabel() {
  return import('../../src/shared/components/labels/AttendanceStatusLabel.vue' /* webpackChunkName: "components/attendance-status-label" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CompletionStatusLabel = function CompletionStatusLabel() {
  return import('../../src/shared/components/labels/CompletionStatusLabel.vue' /* webpackChunkName: "components/completion-status-label" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ShiftLabel = function ShiftLabel() {
  return import('../../src/shared/components/labels/ShiftLabel.vue' /* webpackChunkName: "components/shift-label" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SubjectLabel = function SubjectLabel() {
  return import('../../src/shared/components/labels/SubjectLabel.vue' /* webpackChunkName: "components/subject-label" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherAttendanceStatusLabel = function TeacherAttendanceStatusLabel() {
  return import('../../src/shared/components/labels/TeacherAttendanceStatusLabel.vue' /* webpackChunkName: "components/teacher-attendance-status-label" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TotalLabel = function TotalLabel() {
  return import('../../src/shared/components/labels/TotalLabel.vue' /* webpackChunkName: "components/total-label" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ValidLabel = function ValidLabel() {
  return import('../../src/shared/components/labels/ValidLabel.vue' /* webpackChunkName: "components/valid-label" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PullToAndPaginationContainer = function PullToAndPaginationContainer() {
  return import('../../src/shared/components/pullTo/PullToAndPaginationContainer.vue' /* webpackChunkName: "components/pull-to-and-pagination-container" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PullToContainer = function PullToContainer() {
  return import('../../src/shared/components/pullTo/PullToContainer.vue' /* webpackChunkName: "components/pull-to-container" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PullToLoading = function PullToLoading() {
  return import('../../src/shared/components/pullTo/PullToLoading.vue' /* webpackChunkName: "components/pull-to-loading" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AppHeader = function AppHeader() {
  return import('../../src/domain/fragments/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var StudyHistoryAimCard = function StudyHistoryAimCard() {
  return import('../../src/domain/fragments/aim/StudyHistoryAimCard.vue' /* webpackChunkName: "components/study-history-aim-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TodoProgressAimCard = function TodoProgressAimCard() {
  return import('../../src/domain/fragments/aim/TodoProgressAimCard.vue' /* webpackChunkName: "components/todo-progress-aim-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CustomSubjectLabel = function CustomSubjectLabel() {
  return import('../../src/domain/fragments/reco/exam/CustomSubjectLabel.vue' /* webpackChunkName: "components/custom-subject-label" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceNavBtnGroup = function GuidanceNavBtnGroup() {
  return import('../../src/domain/fragments/reco/guidance/GuidanceNavBtnGroup.vue' /* webpackChunkName: "components/guidance-nav-btn-group" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidancePolicyUpdateDialog = function GuidancePolicyUpdateDialog() {
  return import('../../src/domain/fragments/reco/guidance/GuidancePolicyUpdateDialog.vue' /* webpackChunkName: "components/guidance-policy-update-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GroupedContractedKomaSubjectsInputs = function GroupedContractedKomaSubjectsInputs() {
  return import('../../src/domain/fragments/reco/subject/GroupedContractedKomaSubjectsInputs.vue' /* webpackChunkName: "components/grouped-contracted-koma-subjects-inputs" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ClassroomChangeDialog = function ClassroomChangeDialog() {
  return import('../../src/domain/fragments/user/account/ClassroomChangeDialog.vue' /* webpackChunkName: "components/classroom-change-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ClassroomSelectDialog = function ClassroomSelectDialog() {
  return import('../../src/domain/fragments/user/account/ClassroomSelectDialog.vue' /* webpackChunkName: "components/classroom-select-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var RoomAccessCompletedDialog = function RoomAccessCompletedDialog() {
  return import('../../src/domain/fragments/user/account/RoomAccessCompletedDialog.vue' /* webpackChunkName: "components/room-access-completed-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var UserLogin = function UserLogin() {
  return import('../../src/domain/fragments/user/account/UserLogin.vue' /* webpackChunkName: "components/user-login" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var LoginStatusLabel = function LoginStatusLabel() {
  return import('../../src/domain/fragments/user/student/LoginStatusLabel.vue' /* webpackChunkName: "components/login-status-label" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var StudentCard = function StudentCard() {
  return import('../../src/domain/fragments/user/student/StudentCard.vue' /* webpackChunkName: "components/student-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var StudentInfoPanel = function StudentInfoPanel() {
  return import('../../src/domain/fragments/user/student/StudentInfoPanel.vue' /* webpackChunkName: "components/student-info-panel" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var StudentNavBtnGroup = function StudentNavBtnGroup() {
  return import('../../src/domain/fragments/user/student/StudentNavBtnGroup.vue' /* webpackChunkName: "components/student-nav-btn-group" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var UpdateSharedInfoDialog = function UpdateSharedInfoDialog() {
  return import('../../src/domain/fragments/user/student/UpdateSharedInfoDialog.vue' /* webpackChunkName: "components/update-shared-info-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GroupedManagementUserSelectInput = function GroupedManagementUserSelectInput() {
  return import('../../src/domain/fragments/user/teacher/GroupedManagementUserSelectInput.vue' /* webpackChunkName: "components/grouped-management-user-select-input" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherCard = function TeacherCard() {
  return import('../../src/domain/fragments/user/teacher/TeacherCard.vue' /* webpackChunkName: "components/teacher-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherInfoPanel = function TeacherInfoPanel() {
  return import('../../src/domain/fragments/user/teacher/TeacherInfoPanel.vue' /* webpackChunkName: "components/teacher-info-panel" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherSharedCommentCard = function TeacherSharedCommentCard() {
  return import('../../src/domain/fragments/user/teacherSharedInfo/TeacherSharedCommentCard.vue' /* webpackChunkName: "components/teacher-shared-comment-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherSharedCommentCreateDialog = function TeacherSharedCommentCreateDialog() {
  return import('../../src/domain/fragments/user/teacherSharedInfo/TeacherSharedCommentCreateDialog.vue' /* webpackChunkName: "components/teacher-shared-comment-create-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherSharedCommentEditDialog = function TeacherSharedCommentEditDialog() {
  return import('../../src/domain/fragments/user/teacherSharedInfo/TeacherSharedCommentEditDialog.vue' /* webpackChunkName: "components/teacher-shared-comment-edit-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomaTextbookStudyCreateDialogForTextbookDetailPage = function GuidanceKomaTextbookStudyCreateDialogForTextbookDetailPage() {
  return import('../../src/domain/fragments/reco/contents/textbook/GuidanceKomaTextbookStudyCreateDialogForTextbookDetailPage.vue' /* webpackChunkName: "components/guidance-koma-textbook-study-create-dialog-for-textbook-detail-page" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomaTextbookStudyEditDialogForTextbookDetailPage = function GuidanceKomaTextbookStudyEditDialogForTextbookDetailPage() {
  return import('../../src/domain/fragments/reco/contents/textbook/GuidanceKomaTextbookStudyEditDialogForTextbookDetailPage.vue' /* webpackChunkName: "components/guidance-koma-textbook-study-edit-dialog-for-textbook-detail-page" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var OriginalExamCard = function OriginalExamCard() {
  return import('../../src/domain/fragments/reco/exam/originalExam/OriginalExamCard.vue' /* webpackChunkName: "components/original-exam-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var OriginalExamCreateDialog = function OriginalExamCreateDialog() {
  return import('../../src/domain/fragments/reco/exam/originalExam/OriginalExamCreateDialog.vue' /* webpackChunkName: "components/original-exam-create-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var OriginalExamDataTable = function OriginalExamDataTable() {
  return import('../../src/domain/fragments/reco/exam/originalExam/OriginalExamDataTable.vue' /* webpackChunkName: "components/original-exam-data-table" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var OriginalExamEditDialog = function OriginalExamEditDialog() {
  return import('../../src/domain/fragments/reco/exam/originalExam/OriginalExamEditDialog.vue' /* webpackChunkName: "components/original-exam-edit-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var OriginalExamSubjectCreateDialog = function OriginalExamSubjectCreateDialog() {
  return import('../../src/domain/fragments/reco/exam/originalExam/OriginalExamSubjectCreateDialog.vue' /* webpackChunkName: "components/original-exam-subject-create-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var OriginalExamSubjectEditDialog = function OriginalExamSubjectEditDialog() {
  return import('../../src/domain/fragments/reco/exam/originalExam/OriginalExamSubjectEditDialog.vue' /* webpackChunkName: "components/original-exam-subject-edit-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var OriginalExamSubjectsBulkRegisterDialog = function OriginalExamSubjectsBulkRegisterDialog() {
  return import('../../src/domain/fragments/reco/exam/originalExam/OriginalExamSubjectsBulkRegisterDialog.vue' /* webpackChunkName: "components/original-exam-subjects-bulk-register-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var KomaLabel = function KomaLabel() {
  return import('../../src/domain/fragments/reco/guidance/koma/KomaLabel.vue' /* webpackChunkName: "components/koma-label" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DatePickerForGuidanceHistory = function DatePickerForGuidanceHistory() {
  return import('../../src/domain/fragments/reco/guidance/report/DatePickerForGuidanceHistory.vue' /* webpackChunkName: "components/date-picker-for-guidance-history" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceIndividualTasksMoveDialog = function GuidanceIndividualTasksMoveDialog() {
  return import('../../src/domain/fragments/reco/guidance/report/GuidanceIndividualTasksMoveDialog.vue' /* webpackChunkName: "components/guidance-individual-tasks-move-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceIndividualTasksRegisterDialogForGuidanceReport = function GuidanceIndividualTasksRegisterDialogForGuidanceReport() {
  return import('../../src/domain/fragments/reco/guidance/report/GuidanceIndividualTasksRegisterDialogForGuidanceReport.vue' /* webpackChunkName: "components/guidance-individual-tasks-register-dialog-for-guidance-report" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceIndividualTasksRegisterDialogForGuidanceSchedule = function GuidanceIndividualTasksRegisterDialogForGuidanceSchedule() {
  return import('../../src/domain/fragments/reco/guidance/report/GuidanceIndividualTasksRegisterDialogForGuidanceSchedule.vue' /* webpackChunkName: "components/guidance-individual-tasks-register-dialog-for-guidance-schedule" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceReportValidationErrorDialog = function GuidanceReportValidationErrorDialog() {
  return import('../../src/domain/fragments/reco/guidance/report/GuidanceReportValidationErrorDialog.vue' /* webpackChunkName: "components/guidance-report-validation-error-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ParentReportRegisterDialog = function ParentReportRegisterDialog() {
  return import('../../src/domain/fragments/reco/guidance/report/ParentReportRegisterDialog.vue' /* webpackChunkName: "components/parent-report-register-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomasBulkUpdateBottomDrawer = function GuidanceKomasBulkUpdateBottomDrawer() {
  return import('../../src/domain/fragments/reco/guidance/schedule/GuidanceKomasBulkUpdateBottomDrawer.vue' /* webpackChunkName: "components/guidance-komas-bulk-update-bottom-drawer" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherSelectForRegisterShiftBottomDrawer = function TeacherSelectForRegisterShiftBottomDrawer() {
  return import('../../src/domain/fragments/reco/guidance/shift/TeacherSelectForRegisterShiftBottomDrawer.vue' /* webpackChunkName: "components/teacher-select-for-register-shift-bottom-drawer" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherShiftCalendar = function TeacherShiftCalendar() {
  return import('../../src/domain/fragments/reco/guidance/shift/TeacherShiftCalendar.vue' /* webpackChunkName: "components/teacher-shift-calendar" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherShiftKomaRegisterCard = function TeacherShiftKomaRegisterCard() {
  return import('../../src/domain/fragments/reco/guidance/shift/TeacherShiftKomaRegisterCard.vue' /* webpackChunkName: "components/teacher-shift-koma-register-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherShiftRegisterDialogForTeacherDetailPage = function TeacherShiftRegisterDialogForTeacherDetailPage() {
  return import('../../src/domain/fragments/reco/guidance/shift/TeacherShiftRegisterDialogForTeacherDetailPage.vue' /* webpackChunkName: "components/teacher-shift-register-dialog-for-teacher-detail-page" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherShiftRegisterDialogForTeachersShiftsPage = function TeacherShiftRegisterDialogForTeachersShiftsPage() {
  return import('../../src/domain/fragments/reco/guidance/shift/TeacherShiftRegisterDialogForTeachersShiftsPage.vue' /* webpackChunkName: "components/teacher-shift-register-dialog-for-teachers-shifts-page" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherShiftsBulkRegisterDialog = function TeacherShiftsBulkRegisterDialog() {
  return import('../../src/domain/fragments/reco/guidance/shift/TeacherShiftsBulkRegisterDialog.vue' /* webpackChunkName: "components/teacher-shifts-bulk-register-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ContractKomaRegisterPanel = function ContractKomaRegisterPanel() {
  return import('../../src/domain/fragments/reco/guidance/studentContract/ContractKomaRegisterPanel.vue' /* webpackChunkName: "components/contract-koma-register-panel" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var StudentContractCard = function StudentContractCard() {
  return import('../../src/domain/fragments/reco/guidance/studentContract/StudentContractCard.vue' /* webpackChunkName: "components/student-contract-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var StudentContractCreateDialog = function StudentContractCreateDialog() {
  return import('../../src/domain/fragments/reco/guidance/studentContract/StudentContractCreateDialog.vue' /* webpackChunkName: "components/student-contract-create-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var StudentContractDetailPanel = function StudentContractDetailPanel() {
  return import('../../src/domain/fragments/reco/guidance/studentContract/StudentContractDetailPanel.vue' /* webpackChunkName: "components/student-contract-detail-panel" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var StudentContractEditDialog = function StudentContractEditDialog() {
  return import('../../src/domain/fragments/reco/guidance/studentContract/StudentContractEditDialog.vue' /* webpackChunkName: "components/student-contract-edit-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EditTeacherTaskStudentsDialog = function EditTeacherTaskStudentsDialog() {
  return import('../../src/domain/fragments/reco/task/teacherTask/EditTeacherTaskStudentsDialog.vue' /* webpackChunkName: "components/edit-teacher-task-students-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var StudentSelectTableForCreatingTeacherTask = function StudentSelectTableForCreatingTeacherTask() {
  return import('../../src/domain/fragments/reco/task/teacherTask/StudentSelectTableForCreatingTeacherTask.vue' /* webpackChunkName: "components/student-select-table-for-creating-teacher-task" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherTaskCard = function TeacherTaskCard() {
  return import('../../src/domain/fragments/reco/task/teacherTask/TeacherTaskCard.vue' /* webpackChunkName: "components/teacher-task-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherTaskCreateDialog = function TeacherTaskCreateDialog() {
  return import('../../src/domain/fragments/reco/task/teacherTask/TeacherTaskCreateDialog.vue' /* webpackChunkName: "components/teacher-task-create-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherTaskDetailCard = function TeacherTaskDetailCard() {
  return import('../../src/domain/fragments/reco/task/teacherTask/TeacherTaskDetailCard.vue' /* webpackChunkName: "components/teacher-task-detail-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherTaskEditDialog = function TeacherTaskEditDialog() {
  return import('../../src/domain/fragments/reco/task/teacherTask/TeacherTaskEditDialog.vue' /* webpackChunkName: "components/teacher-task-edit-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherTaskStudentsSelectTable = function TeacherTaskStudentsSelectTable() {
  return import('../../src/domain/fragments/reco/task/teacherTask/TeacherTaskStudentsSelectTable.vue' /* webpackChunkName: "components/teacher-task-students-select-table" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherTaskCheckCard = function TeacherTaskCheckCard() {
  return import('../../src/domain/fragments/reco/task/teacherTaskCheck/TeacherTaskCheckCard.vue' /* webpackChunkName: "components/teacher-task-check-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherTaskCheckDetailCard = function TeacherTaskCheckDetailCard() {
  return import('../../src/domain/fragments/reco/task/teacherTaskCheck/TeacherTaskCheckDetailCard.vue' /* webpackChunkName: "components/teacher-task-check-detail-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherTaskCheckTable = function TeacherTaskCheckTable() {
  return import('../../src/domain/fragments/reco/task/teacherTaskCheck/TeacherTaskCheckTable.vue' /* webpackChunkName: "components/teacher-task-check-table" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var NotScheduledStudentCard = function NotScheduledStudentCard() {
  return import('../../src/domain/fragments/user/student/guidanceSchedule/NotScheduledStudentCard.vue' /* webpackChunkName: "components/not-scheduled-student-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var NotScheduledStudentPanel = function NotScheduledStudentPanel() {
  return import('../../src/domain/fragments/user/student/guidanceSchedule/NotScheduledStudentPanel.vue' /* webpackChunkName: "components/not-scheduled-student-panel" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var StudentTagsEditDialog = function StudentTagsEditDialog() {
  return import('../../src/domain/fragments/reco/contents/tag/studentTag/StudentTagsEditDialog.vue' /* webpackChunkName: "components/student-tags-edit-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ImportantLabel = function ImportantLabel() {
  return import('../../src/domain/fragments/reco/contents/textbook/common/ImportantLabel.vue' /* webpackChunkName: "components/important-label" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextbookCard = function TextbookCard() {
  return import('../../src/domain/fragments/reco/contents/textbook/common/TextbookCard.vue' /* webpackChunkName: "components/textbook-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextbookCardForCreated = function TextbookCardForCreated() {
  return import('../../src/domain/fragments/reco/contents/textbook/common/TextbookCardForCreated.vue' /* webpackChunkName: "components/textbook-card-for-created" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextbookDetailCard = function TextbookDetailCard() {
  return import('../../src/domain/fragments/reco/contents/textbook/common/TextbookDetailCard.vue' /* webpackChunkName: "components/textbook-detail-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextbookDetailCardForGuidanceReport = function TextbookDetailCardForGuidanceReport() {
  return import('../../src/domain/fragments/reco/contents/textbook/common/TextbookDetailCardForGuidanceReport.vue' /* webpackChunkName: "components/textbook-detail-card-for-guidance-report" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextbookListHeader = function TextbookListHeader() {
  return import('../../src/domain/fragments/reco/contents/textbook/common/TextbookListHeader.vue' /* webpackChunkName: "components/textbook-list-header" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var LessonPageRangeSelectFormDialog = function LessonPageRangeSelectFormDialog() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/LessonPageRangeSelectFormDialog.vue' /* webpackChunkName: "components/lesson-page-range-select-form-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextbookProgress = function TextbookProgress() {
  return import('../../src/domain/fragments/reco/contents/textbook/progressBar/TextbookProgress.vue' /* webpackChunkName: "components/textbook-progress" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SchoolLessonProgressBtn = function SchoolLessonProgressBtn() {
  return import('../../src/domain/fragments/reco/contents/textbook/progressBtn/SchoolLessonProgressBtn.vue' /* webpackChunkName: "components/school-lesson-progress-btn" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TotalStudyProgressBtn = function TotalStudyProgressBtn() {
  return import('../../src/domain/fragments/reco/contents/textbook/progressBtn/TotalStudyProgressBtn.vue' /* webpackChunkName: "components/total-study-progress-btn" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AdditionalSchoolExamSubjectCreateDialog = function AdditionalSchoolExamSubjectCreateDialog() {
  return import('../../src/domain/fragments/reco/exam/schoolExam/additional/AdditionalSchoolExamSubjectCreateDialog.vue' /* webpackChunkName: "components/additional-school-exam-subject-create-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AdditionalSchoolExamSubjectEditDialog = function AdditionalSchoolExamSubjectEditDialog() {
  return import('../../src/domain/fragments/reco/exam/schoolExam/additional/AdditionalSchoolExamSubjectEditDialog.vue' /* webpackChunkName: "components/additional-school-exam-subject-edit-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SchoolExamCard = function SchoolExamCard() {
  return import('../../src/domain/fragments/reco/exam/schoolExam/common/SchoolExamCard.vue' /* webpackChunkName: "components/school-exam-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SchoolExamDataTable = function SchoolExamDataTable() {
  return import('../../src/domain/fragments/reco/exam/schoolExam/common/SchoolExamDataTable.vue' /* webpackChunkName: "components/school-exam-data-table" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SchoolExamPanel = function SchoolExamPanel() {
  return import('../../src/domain/fragments/reco/exam/schoolExam/common/SchoolExamPanel.vue' /* webpackChunkName: "components/school-exam-panel" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SchoolExamSubjectsBulkRegisterDialog = function SchoolExamSubjectsBulkRegisterDialog() {
  return import('../../src/domain/fragments/reco/exam/schoolExam/common/SchoolExamSubjectsBulkRegisterDialog.vue' /* webpackChunkName: "components/school-exam-subjects-bulk-register-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DefaultSchoolExamSubjectUpdateDialog = function DefaultSchoolExamSubjectUpdateDialog() {
  return import('../../src/domain/fragments/reco/exam/schoolExam/default/DefaultSchoolExamSubjectUpdateDialog.vue' /* webpackChunkName: "components/default-school-exam-subject-update-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SchoolExamDataTableForScoreTrend = function SchoolExamDataTableForScoreTrend() {
  return import('../../src/domain/fragments/reco/exam/schoolExam/scoreTrend/SchoolExamDataTableForScoreTrend.vue' /* webpackChunkName: "components/school-exam-data-table-for-score-trend" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SchoolExamScoreChart = function SchoolExamScoreChart() {
  return import('../../src/domain/fragments/reco/exam/schoolExam/scoreTrend/SchoolExamScoreChart.vue' /* webpackChunkName: "components/school-exam-score-chart" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomaCard = function GuidanceKomaCard() {
  return import('../../src/domain/fragments/reco/guidance/koma/calendarForStudent/GuidanceKomaCard.vue' /* webpackChunkName: "components/guidance-koma-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomaPanelsSortedByKomaTemplate = function GuidanceKomaPanelsSortedByKomaTemplate() {
  return import('../../src/domain/fragments/reco/guidance/koma/guidanceSchedule/GuidanceKomaPanelsSortedByKomaTemplate.vue' /* webpackChunkName: "components/guidance-koma-panels-sorted-by-koma-template" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomaPanelsSortedByManager = function GuidanceKomaPanelsSortedByManager() {
  return import('../../src/domain/fragments/reco/guidance/koma/guidanceSchedule/GuidanceKomaPanelsSortedByManager.vue' /* webpackChunkName: "components/guidance-koma-panels-sorted-by-manager" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AimAtReportCard = function AimAtReportCard() {
  return import('../../src/domain/fragments/reco/guidance/report/aim/AimAtReportCard.vue' /* webpackChunkName: "components/aim-at-report-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AimAtReportsRegisterDialog = function AimAtReportsRegisterDialog() {
  return import('../../src/domain/fragments/reco/guidance/report/aim/AimAtReportsRegisterDialog.vue' /* webpackChunkName: "components/aim-at-reports-register-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceTestCreateDialog = function GuidanceTestCreateDialog() {
  return import('../../src/domain/fragments/reco/guidance/report/guidanceTest/GuidanceTestCreateDialog.vue' /* webpackChunkName: "components/guidance-test-create-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceReportContentPanel = function GuidanceReportContentPanel() {
  return import('../../src/domain/fragments/reco/guidance/report/panel/GuidanceReportContentPanel.vue' /* webpackChunkName: "components/guidance-report-content-panel" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceReportGuidanceTaskPanel = function GuidanceReportGuidanceTaskPanel() {
  return import('../../src/domain/fragments/reco/guidance/report/panel/GuidanceReportGuidanceTaskPanel.vue' /* webpackChunkName: "components/guidance-report-guidance-task-panel" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceReportKomaInfoPanel = function GuidanceReportKomaInfoPanel() {
  return import('../../src/domain/fragments/reco/guidance/report/panel/GuidanceReportKomaInfoPanel.vue' /* webpackChunkName: "components/guidance-report-koma-info-panel" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceReportPanel = function GuidanceReportPanel() {
  return import('../../src/domain/fragments/reco/guidance/report/panel/GuidanceReportPanel.vue' /* webpackChunkName: "components/guidance-report-panel" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomaTextbookStudyCard = function GuidanceKomaTextbookStudyCard() {
  return import('../../src/domain/fragments/reco/guidance/report/progress/GuidanceKomaTextbookStudyCard.vue' /* webpackChunkName: "components/guidance-koma-textbook-study-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceProgressLapInputBtn = function GuidanceProgressLapInputBtn() {
  return import('../../src/domain/fragments/reco/guidance/report/progress/GuidanceProgressLapInputBtn.vue' /* webpackChunkName: "components/guidance-progress-lap-input-btn" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceReviewCard = function GuidanceReviewCard() {
  return import('../../src/domain/fragments/reco/guidance/report/review/GuidanceReviewCard.vue' /* webpackChunkName: "components/guidance-review-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceReviewsRegisterDialog = function GuidanceReviewsRegisterDialog() {
  return import('../../src/domain/fragments/reco/guidance/report/review/GuidanceReviewsRegisterDialog.vue' /* webpackChunkName: "components/guidance-reviews-register-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FixedTeacherShiftDetailFormDialog = function FixedTeacherShiftDetailFormDialog() {
  return import('../../src/domain/fragments/reco/guidance/shift/fixedTeacherShiftKoma/FixedTeacherShiftDetailFormDialog.vue' /* webpackChunkName: "components/fixed-teacher-shift-detail-form-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FixedTeacherShiftKomaRegisterCard = function FixedTeacherShiftKomaRegisterCard() {
  return import('../../src/domain/fragments/reco/guidance/shift/fixedTeacherShiftKoma/FixedTeacherShiftKomaRegisterCard.vue' /* webpackChunkName: "components/fixed-teacher-shift-koma-register-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FixedTeacherShiftKomasApplyDialog = function FixedTeacherShiftKomasApplyDialog() {
  return import('../../src/domain/fragments/reco/guidance/shift/fixedTeacherShiftKoma/FixedTeacherShiftKomasApplyDialog.vue' /* webpackChunkName: "components/fixed-teacher-shift-komas-apply-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FixedTeacherShiftKomasRegisterDialog = function FixedTeacherShiftKomasRegisterDialog() {
  return import('../../src/domain/fragments/reco/guidance/shift/fixedTeacherShiftKoma/FixedTeacherShiftKomasRegisterDialog.vue' /* webpackChunkName: "components/fixed-teacher-shift-komas-register-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TeacherFixedShiftsPanels = function TeacherFixedShiftsPanels() {
  return import('../../src/domain/fragments/reco/guidance/shift/fixedTeacherShiftKoma/TeacherFixedShiftsPanels.vue' /* webpackChunkName: "components/teacher-fixed-shifts-panels" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextbookCreateDialog = function TextbookCreateDialog() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/create/TextbookCreateDialog.vue' /* webpackChunkName: "components/textbook-create-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextbookEditDialog = function TextbookEditDialog() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/edit/TextbookEditDialog.vue' /* webpackChunkName: "components/textbook-edit-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextbookEditForm = function TextbookEditForm() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/edit/TextbookEditForm.vue' /* webpackChunkName: "components/textbook-edit-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var OthersTextbookSearchBottomDrawer = function OthersTextbookSearchBottomDrawer() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/search/OthersTextbookSearchBottomDrawer.vue' /* webpackChunkName: "components/others-textbook-search-bottom-drawer" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PreviousTextbookSearchBottomDrawer = function PreviousTextbookSearchBottomDrawer() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/search/PreviousTextbookSearchBottomDrawer.vue' /* webpackChunkName: "components/previous-textbook-search-bottom-drawer" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextbookSearchBottomDrawer = function TextbookSearchBottomDrawer() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/search/TextbookSearchBottomDrawer.vue' /* webpackChunkName: "components/textbook-search-bottom-drawer" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextbookTemplateSearchBottomDrawer = function TextbookTemplateSearchBottomDrawer() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/search/TextbookTemplateSearchBottomDrawer.vue' /* webpackChunkName: "components/textbook-template-search-bottom-drawer" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceProgressPagination = function GuidanceProgressPagination() {
  return import('../../src/domain/fragments/reco/contents/textbook/textbookStudy/guidanceProgress/GuidanceProgressPagination.vue' /* webpackChunkName: "components/guidance-progress-pagination" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SchoolLessonProgressPagination = function SchoolLessonProgressPagination() {
  return import('../../src/domain/fragments/reco/contents/textbook/textbookStudy/schoolLessonProgress/SchoolLessonProgressPagination.vue' /* webpackChunkName: "components/school-lesson-progress-pagination" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TotalStudyProgressPagination = function TotalStudyProgressPagination() {
  return import('../../src/domain/fragments/reco/contents/textbook/textbookStudy/totalStudyProgress/TotalStudyProgressPagination.vue' /* webpackChunkName: "components/total-study-progress-pagination" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomasApplyDialog = function GuidanceKomasApplyDialog() {
  return import('../../src/domain/fragments/reco/guidance/koma/register/apply/GuidanceKomasApplyDialog.vue' /* webpackChunkName: "components/guidance-komas-apply-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomaCardForBulkRegistration = function GuidanceKomaCardForBulkRegistration() {
  return import('../../src/domain/fragments/reco/guidance/koma/register/bulkRegisterDialog/GuidanceKomaCardForBulkRegistration.vue' /* webpackChunkName: "components/guidance-koma-card-for-bulk-registration" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomasDialogForBulkRegistration = function GuidanceKomasDialogForBulkRegistration() {
  return import('../../src/domain/fragments/reco/guidance/koma/register/bulkRegisterDialog/GuidanceKomasDialogForBulkRegistration.vue' /* webpackChunkName: "components/guidance-komas-dialog-for-bulk-registration" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomaCardForSingleRegistration = function GuidanceKomaCardForSingleRegistration() {
  return import('../../src/domain/fragments/reco/guidance/koma/register/singleRegisterDialog/GuidanceKomaCardForSingleRegistration.vue' /* webpackChunkName: "components/guidance-koma-card-for-single-registration" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomaDetailFormDialogForSingleRegistration = function GuidanceKomaDetailFormDialogForSingleRegistration() {
  return import('../../src/domain/fragments/reco/guidance/koma/register/singleRegisterDialog/GuidanceKomaDetailFormDialogForSingleRegistration.vue' /* webpackChunkName: "components/guidance-koma-detail-form-dialog-for-single-registration" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomaDialogForSingleRegistration = function GuidanceKomaDialogForSingleRegistration() {
  return import('../../src/domain/fragments/reco/guidance/koma/register/singleRegisterDialog/GuidanceKomaDialogForSingleRegistration.vue' /* webpackChunkName: "components/guidance-koma-dialog-for-single-registration" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceCustomTestCard = function GuidanceCustomTestCard() {
  return import('../../src/domain/fragments/reco/guidance/report/guidanceTest/guidanceCustomTest/GuidanceCustomTestCard.vue' /* webpackChunkName: "components/guidance-custom-test-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceCustomTestCreateForm = function GuidanceCustomTestCreateForm() {
  return import('../../src/domain/fragments/reco/guidance/report/guidanceTest/guidanceCustomTest/GuidanceCustomTestCreateForm.vue' /* webpackChunkName: "components/guidance-custom-test-create-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceCustomTestEditDialog = function GuidanceCustomTestEditDialog() {
  return import('../../src/domain/fragments/reco/guidance/report/guidanceTest/guidanceCustomTest/GuidanceCustomTestEditDialog.vue' /* webpackChunkName: "components/guidance-custom-test-edit-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceJukuTestCard = function GuidanceJukuTestCard() {
  return import('../../src/domain/fragments/reco/guidance/report/guidanceTest/guidanceJukuTest/GuidanceJukuTestCard.vue' /* webpackChunkName: "components/guidance-juku-test-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceJukuTestCreateForm = function GuidanceJukuTestCreateForm() {
  return import('../../src/domain/fragments/reco/guidance/report/guidanceTest/guidanceJukuTest/GuidanceJukuTestCreateForm.vue' /* webpackChunkName: "components/guidance-juku-test-create-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceJukuTestEditDialog = function GuidanceJukuTestEditDialog() {
  return import('../../src/domain/fragments/reco/guidance/report/guidanceTest/guidanceJukuTest/GuidanceJukuTestEditDialog.vue' /* webpackChunkName: "components/guidance-juku-test-edit-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceJukuTestListDialog = function GuidanceJukuTestListDialog() {
  return import('../../src/domain/fragments/reco/guidance/report/guidanceTest/guidanceJukuTest/GuidanceJukuTestListDialog.vue' /* webpackChunkName: "components/guidance-juku-test-list-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomaTextbookStudyCreateDialog = function GuidanceKomaTextbookStudyCreateDialog() {
  return import('../../src/domain/fragments/reco/guidance/report/progress/create/GuidanceKomaTextbookStudyCreateDialog.vue' /* webpackChunkName: "components/guidance-koma-textbook-study-create-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomaTextbookStudyCreateForm = function GuidanceKomaTextbookStudyCreateForm() {
  return import('../../src/domain/fragments/reco/guidance/report/progress/create/GuidanceKomaTextbookStudyCreateForm.vue' /* webpackChunkName: "components/guidance-koma-textbook-study-create-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextbookSearchBottomDrawerForGuidanceProgress = function TextbookSearchBottomDrawerForGuidanceProgress() {
  return import('../../src/domain/fragments/reco/guidance/report/progress/create/TextbookSearchBottomDrawerForGuidanceProgress.vue' /* webpackChunkName: "components/textbook-search-bottom-drawer-for-guidance-progress" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomaTextbookStudyUpdateDialog = function GuidanceKomaTextbookStudyUpdateDialog() {
  return import('../../src/domain/fragments/reco/guidance/report/progress/update/GuidanceKomaTextbookStudyUpdateDialog.vue' /* webpackChunkName: "components/guidance-koma-textbook-study-update-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DetailInputFormForCreatingTextbookByOthers = function DetailInputFormForCreatingTextbookByOthers() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/create/byOthers/DetailInputFormForCreatingTextbookByOthers.vue' /* webpackChunkName: "components/detail-input-form-for-creating-textbook-by-others" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FormForCreatingTextbookByOthers = function FormForCreatingTextbookByOthers() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/create/byOthers/FormForCreatingTextbookByOthers.vue' /* webpackChunkName: "components/form-for-creating-textbook-by-others" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DetailInputFormForCreatingTextbookByOwnTextbooks = function DetailInputFormForCreatingTextbookByOwnTextbooks() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/create/byOwn/DetailInputFormForCreatingTextbookByOwnTextbooks.vue' /* webpackChunkName: "components/detail-input-form-for-creating-textbook-by-own-textbooks" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FormForCreatingTextbookByOwnTextbooks = function FormForCreatingTextbookByOwnTextbooks() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/create/byOwn/FormForCreatingTextbookByOwnTextbooks.vue' /* webpackChunkName: "components/form-for-creating-textbook-by-own-textbooks" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextbookTemplateCard = function TextbookTemplateCard() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/create/byTemplate/TextbookTemplateCard.vue' /* webpackChunkName: "components/textbook-template-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FormForCreatingTextbook = function FormForCreatingTextbook() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/create/new/FormForCreatingTextbook.vue' /* webpackChunkName: "components/form-for-creating-textbook" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomaDetailFormForTransfer = function GuidanceKomaDetailFormForTransfer() {
  return import('../../src/domain/fragments/reco/guidance/koma/register/singleRegisterDialog/transfer/GuidanceKomaDetailFormForTransfer.vue' /* webpackChunkName: "components/guidance-koma-detail-form-for-transfer" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomaTransferDialog = function GuidanceKomaTransferDialog() {
  return import('../../src/domain/fragments/reco/guidance/koma/register/singleRegisterDialog/transfer/GuidanceKomaTransferDialog.vue' /* webpackChunkName: "components/guidance-koma-transfer-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DetailInputFormForCreatingTextbookByTemplatesFilteredBySchool = function DetailInputFormForCreatingTextbookByTemplatesFilteredBySchool() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/create/byTemplate/filterBySchool/DetailInputFormForCreatingTextbookByTemplatesFilteredBySchool.vue' /* webpackChunkName: "components/detail-input-form-for-creating-textbook-by-templates-filtered-by-school" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FormForCreatingTextbookByTemplatesFilteredBySchool = function FormForCreatingTextbookByTemplatesFilteredBySchool() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/create/byTemplate/filterBySchool/FormForCreatingTextbookByTemplatesFilteredBySchool.vue' /* webpackChunkName: "components/form-for-creating-textbook-by-templates-filtered-by-school" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DetailFormForCreatingTextbookByTemplatesFilteredByTextbookTags = function DetailFormForCreatingTextbookByTemplatesFilteredByTextbookTags() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/create/byTemplate/filterByTextbookTag/DetailFormForCreatingTextbookByTemplatesFilteredByTextbookTags.vue' /* webpackChunkName: "components/detail-form-for-creating-textbook-by-templates-filtered-by-textbook-tags" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FormForCreatingTextbookByTemplatesFilteredByTextbookTags = function FormForCreatingTextbookByTemplatesFilteredByTextbookTags() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/create/byTemplate/filterByTextbookTag/FormForCreatingTextbookByTemplatesFilteredByTextbookTags.vue' /* webpackChunkName: "components/form-for-creating-textbook-by-templates-filtered-by-textbook-tags" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TemplateSelectionForCreatingTextbookByTemplatesFilteredByTextbookTags = function TemplateSelectionForCreatingTextbookByTemplatesFilteredByTextbookTags() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/create/byTemplate/filterByTextbookTag/TemplateSelectionForCreatingTextbookByTemplatesFilteredByTextbookTags.vue' /* webpackChunkName: "components/template-selection-for-creating-textbook-by-templates-filtered-by-textbook-tags" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextbookTagsSelection = function TextbookTagsSelection() {
  return import('../../src/domain/fragments/reco/contents/textbook/form/create/byTemplate/filterByTextbookTag/TextbookTagsSelection.vue' /* webpackChunkName: "components/textbook-tags-selection" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GuidanceKomaCardForTransfer = function GuidanceKomaCardForTransfer() {
  return import('../../src/domain/fragments/reco/guidance/koma/register/singleRegisterDialog/transfer/komaList/GuidanceKomaCardForTransfer.vue' /* webpackChunkName: "components/guidance-koma-card-for-transfer" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options;
  }
  var propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {});
  return {
    render: function render(h) {
      var attrs = {};
      var props = {};
      for (var key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key];
        } else {
          attrs[key] = this.$attrs[key];
        }
      }
      return h(options, {
        on: this.$listeners,
        attrs: attrs,
        props: props,
        scopedSlots: this.$scopedSlots
      }, this.$slots.default);
    }
  };
}