import "core-js/modules/es.array.concat.js";
/* eslint-disable */
// prettier-ignore

// prettier-ignore

// prettier-ignore

// prettier-ignore
export var pagesPath = {
  home: {
    guidance_schedules: {
      $url: function $url(url) {
        return {
          path: '/home/guidance-schedules',
          hash: url === null || url === void 0 ? void 0 : url.hash
        };
      },
      $name: function $name() {
        return "home-guidance-schedules";
      }
    },
    student: {
      _studentId: function _studentId(studentId) {
        return {
          exams: {
            original: {
              _originalExamId: function _originalExamId(originalExamId) {
                return {
                  $url: function $url(url) {
                    return {
                      path: "/home/student/".concat(studentId, "/exams/original").concat(originalExamId !== undefined ? "/".concat(originalExamId) : ''),
                      hash: url === null || url === void 0 ? void 0 : url.hash
                    };
                  },
                  $name: function $name() {
                    return "home-student-studentId-exams-original-originalExamId";
                  }
                };
              }
            },
            school: {
              _schoolExamId: function _schoolExamId(schoolExamId) {
                return {
                  $url: function $url(url) {
                    return {
                      path: "/home/student/".concat(studentId, "/exams/school").concat(schoolExamId !== undefined ? "/".concat(schoolExamId) : ''),
                      hash: url === null || url === void 0 ? void 0 : url.hash
                    };
                  },
                  $name: function $name() {
                    return "home-student-studentId-exams-school-schoolExamId";
                  }
                };
              },
              score_trend: {
                $url: function $url(url) {
                  return {
                    path: "/home/student/".concat(studentId, "/exams/school/score-trend"),
                    hash: url === null || url === void 0 ? void 0 : url.hash
                  };
                },
                $name: function $name() {
                  return "home-student-studentId-exams-school-score-trend";
                }
              }
            },
            $url: function $url(url) {
              return {
                path: "/home/student/".concat(studentId, "/exams"),
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            },
            $name: function $name() {
              return "home-student-studentId-exams";
            }
          },
          guidance_report: {
            histories: {
              $url: function $url(url) {
                return {
                  path: "/home/student/".concat(studentId, "/guidance-report/histories"),
                  hash: url === null || url === void 0 ? void 0 : url.hash
                };
              },
              $name: function $name() {
                return "home-student-studentId-guidance-report-histories";
              }
            },
            $url: function $url(url) {
              return {
                path: "/home/student/".concat(studentId, "/guidance-report"),
                query: url === null || url === void 0 ? void 0 : url.query,
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            },
            $name: function $name() {
              return "home-student-studentId-guidance-report";
            }
          },
          info: {
            $url: function $url(url) {
              return {
                path: "/home/student/".concat(studentId, "/info"),
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            },
            $name: function $name() {
              return "home-student-studentId-info";
            }
          },
          komas: {
            guidance_koma_calendar: {
              $url: function $url(url) {
                return {
                  path: "/home/student/".concat(studentId, "/komas/guidance-koma-calendar"),
                  query: url === null || url === void 0 ? void 0 : url.query,
                  hash: url === null || url === void 0 ? void 0 : url.hash
                };
              },
              $name: function $name() {
                return "home-student-studentId-komas-guidance-koma-calendar";
              }
            },
            student_contract_list: {
              _contractId: function _contractId(contractId) {
                return {
                  $url: function $url(url) {
                    return {
                      path: "/home/student/".concat(studentId, "/komas/student-contract-list").concat(contractId !== undefined ? "/".concat(contractId) : ''),
                      hash: url === null || url === void 0 ? void 0 : url.hash
                    };
                  },
                  $name: function $name() {
                    return "home-student-studentId-komas-student-contract-list-contractId";
                  }
                };
              },
              $url: function $url(url) {
                return {
                  path: "/home/student/".concat(studentId, "/komas/student-contract-list"),
                  hash: url === null || url === void 0 ? void 0 : url.hash
                };
              },
              $name: function $name() {
                return "home-student-studentId-komas-student-contract-list";
              }
            }
          },
          tasks: {
            teacherTaskChecks: {
              _taskCheckId: function _taskCheckId(taskCheckId) {
                return {
                  $url: function $url(url) {
                    return {
                      path: "/home/student/".concat(studentId, "/tasks/teacherTaskChecks").concat(taskCheckId !== undefined ? "/".concat(taskCheckId) : ''),
                      hash: url === null || url === void 0 ? void 0 : url.hash
                    };
                  },
                  $name: function $name() {
                    return "home-student-studentId-tasks-teacherTaskChecks-taskCheckId";
                  }
                };
              },
              $url: function $url(url) {
                return {
                  path: "/home/student/".concat(studentId, "/tasks/teacherTaskChecks"),
                  hash: url === null || url === void 0 ? void 0 : url.hash
                };
              },
              $name: function $name() {
                return "home-student-studentId-tasks-teacherTaskChecks";
              }
            },
            todo_progress: {
              $url: function $url(url) {
                return {
                  path: "/home/student/".concat(studentId, "/tasks/todo-progress"),
                  hash: url === null || url === void 0 ? void 0 : url.hash
                };
              },
              $name: function $name() {
                return "home-student-studentId-tasks-todo-progress";
              }
            }
          },
          teacher_shared_info: {
            $url: function $url(url) {
              return {
                path: "/home/student/".concat(studentId, "/teacher-shared-info"),
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            },
            $name: function $name() {
              return "home-student-studentId-teacher-shared-info";
            }
          },
          textbooks: {
            list: {
              _textbookId: function _textbookId(textbookId) {
                return {
                  $url: function $url(url) {
                    return {
                      path: "/home/student/".concat(studentId, "/textbooks/list").concat(textbookId !== undefined ? "/".concat(textbookId) : ''),
                      hash: url === null || url === void 0 ? void 0 : url.hash
                    };
                  },
                  $name: function $name() {
                    return "home-student-studentId-textbooks-list-textbookId";
                  }
                };
              },
              $url: function $url(url) {
                return {
                  path: "/home/student/".concat(studentId, "/textbooks/list"),
                  hash: url === null || url === void 0 ? void 0 : url.hash
                };
              },
              $name: function $name() {
                return "home-student-studentId-textbooks-list";
              }
            },
            study_history: {
              $url: function $url(url) {
                return {
                  path: "/home/student/".concat(studentId, "/textbooks/study-history"),
                  hash: url === null || url === void 0 ? void 0 : url.hash
                };
              },
              $name: function $name() {
                return "home-student-studentId-textbooks-study-history";
              }
            }
          }
        };
      }
    },
    students: {
      $url: function $url(url) {
        return {
          path: '/home/students',
          hash: url === null || url === void 0 ? void 0 : url.hash
        };
      },
      $name: function $name() {
        return "home-students";
      }
    },
    tasks: {
      teacher: {
        _taskId: function _taskId(taskId) {
          return {
            $url: function $url(url) {
              return {
                path: "/home/tasks/teacher".concat(taskId !== undefined ? "/".concat(taskId) : ''),
                hash: url === null || url === void 0 ? void 0 : url.hash
              };
            },
            $name: function $name() {
              return "home-tasks-teacher-taskId";
            }
          };
        },
        $url: function $url(url) {
          return {
            path: '/home/tasks/teacher',
            hash: url === null || url === void 0 ? void 0 : url.hash
          };
        },
        $name: function $name() {
          return "home-tasks-teacher";
        }
      }
    },
    teachers: {
      _teacherId: function _teacherId(teacherId) {
        return {
          $url: function $url(url) {
            return {
              path: "/home/teachers".concat(teacherId !== undefined ? "/".concat(teacherId) : ''),
              hash: url === null || url === void 0 ? void 0 : url.hash
            };
          },
          $name: function $name() {
            return "home-teachers-teacherId";
          }
        };
      },
      list: {
        $url: function $url(url) {
          return {
            path: '/home/teachers/list',
            hash: url === null || url === void 0 ? void 0 : url.hash
          };
        },
        $name: function $name() {
          return "home-teachers-list";
        }
      },
      shifts: {
        $url: function $url(url) {
          return {
            path: '/home/teachers/shifts',
            hash: url === null || url === void 0 ? void 0 : url.hash
          };
        },
        $name: function $name() {
          return "home-teachers-shifts";
        }
      }
    }
  },
  maintenance: {
    $url: function $url(url) {
      return {
        path: '/maintenance',
        hash: url === null || url === void 0 ? void 0 : url.hash
      };
    },
    $name: function $name() {
      return "maintenance";
    }
  },
  sign_in: {
    classroom: {
      $url: function $url(url) {
        return {
          path: '/sign-in/classroom',
          hash: url === null || url === void 0 ? void 0 : url.hash
        };
      },
      $name: function $name() {
        return "sign-in-classroom";
      }
    },
    teacher: {
      $url: function $url(url) {
        return {
          path: '/sign-in/teacher',
          hash: url === null || url === void 0 ? void 0 : url.hash
        };
      },
      $name: function $name() {
        return "sign-in-teacher";
      }
    }
  },
  $url: function $url(url) {
    return {
      path: '/',
      hash: url === null || url === void 0 ? void 0 : url.hash
    };
  },
  $name: function $name() {
    return "";
  }
};

// prettier-ignore

// prettier-ignore

// prettier-ignore

// prettier-ignore

// prettier-ignore

// prettier-ignore
var pathPlugin = function pathPlugin(_, inject) {
  inject('pagesPath', pagesPath);
};

// prettier-ignore
export default pathPlugin;